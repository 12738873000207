import React, { useContext } from 'react';
import { Space } from '@ederzadravec/jade-ui';
import { Container } from './filters.style';
import { Select } from 'components';
import { InputText } from './components/inputText';

import { ContextLanguage } from 'contexts';

export const Filters = ({ data, filter, setFilter }) => {
  const cxtLang = useContext(ContextLanguage);
  return (
    <Container>
      <InputText
        style={{}}
        label="Pesquisar"
        onChange={e => setFilter({ ...filter, search: e.target.value })}
      />

      <Space left={1} />

      <Select
        label={
          (cxtLang.lang === 'pt' && 'Categoria') ||
          (cxtLang.lang === 'en' && 'Category') ||
          (cxtLang.lang === 'es' && 'Categoría')
        }
        value={filter.category}
        data={data?.categories}
        onChange={item => {
          setFilter({ ...filter, category: item.value });
        }}
      />

      <Space left={1} />

      <Select
        label={
          (cxtLang.lang === 'pt' && 'Ordenar Por') ||
          (cxtLang.lang === 'en' && 'Order by') ||
          (cxtLang.lang === 'es' && 'Ordenar por')
        }
        value={filter.order}
        data={[
          {
            label: 'A-Z',
            value: 'asc',
          },
          {
            label: 'Z-A',
            value: 'desc',
          },
        ]}
        onChange={item => {
          setFilter({ ...filter, order: item.value });
        }}
      />
    </Container>
  );
};
