import React from 'react';
import styled from 'styled-components';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const Container = styled.div`
  display: inline-flex;
`;

const Nav = styled.div`
  border: 1px solid;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;

  border-color: ${({ active, theme }) => (active ? theme.palette.primary.main : '#dedede')};
  color: ${({ active, theme }) => (active ? theme.palette.primary.main : '')};
`;

const IconNext = styled(FaChevronRight)``;

const IconPrev = styled(FaChevronLeft)``;

export const Pagination = ({ total, page, limit, onChange, ...props }) => {
  const totalPages = Math.ceil(total / limit);

  const handleOnChange = page => () => {
    onChange(page);
  };

  if (total <= limit) return null;

  return (
    <Container {...props}>
      {/* <Nav>
        <IconPrev onClick={handleOnChange(1)} />
      </Nav> */}

      {[...new Array(totalPages)].map((item, key) => (
        <Nav key={key} onClick={handleOnChange(key + 1)} active={key + 1 === page}>
          {key + 1}
        </Nav>
      ))}

      {/* <Nav>
        <IconNext onClick={handleOnChange(totalPages)} />
      </Nav> */}
    </Container>
  );
};

Pagination.defaultProps = {
  total: 0,
  page: 0,
  limit: 8,
  onChange: () => {},
};

//  totalItens="48" currentPage="2" itensPerPage="24"
