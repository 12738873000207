import styled from 'styled-components';
import { Button as JDButton } from '@ederzadravec/jade-ui';
import { Text } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const LinkAction = styled(Text)`
  margin-right: 8px;
`;

export const ContentButton = styled.div`
  display: flex;
`;

export const Button = styled(JDButton)`
  margin-left: 16px;
`;
