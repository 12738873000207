import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  height: auto;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;