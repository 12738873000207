export const formConfirm = {
  es: {
    textvalidation: 'Validación',
    labelFieldEmail: 'E-mail',
    labelButton: 'VALIDAR',
    uriBackToLogin: 'Volver a iniciar sesión',
    textInvalidEmail: 'Email inválido. No se puede continuar con el registro.',
    textCompleteRegistration:
      'Consulte en su correo electrónico el enlace para completar el registro en nuestro sitio web.',
  },
  en: {
    textvalidation: 'Validation',
    labelFieldEmail: 'E-mail',
    labelButton: 'VALIDATE',
    uriBackToLogin: 'Back to login',
    textInvalidEmail: 'Invalid email. Unable to proceed with registration.',
    textCompleteRegistration:
      'Check in your email the link to complete the registration on our website.',
  },
  pt: {
    textvalidation: 'Validação',
    labelFieldEmail: 'E-mail',
    labelButton: 'VALIDAR',
    uriBackToLogin: 'Volte ao login',
    textInvalidEmail: 'Email Inválido. Impossível prosseguir com o cadastro.',
    textCompleteRegistration:
      'Confira em seu e-mail o link para concluir o cadastro em nosso site.',
  },
};
