import React, { useContext, useState } from 'react';

import { Container, Image, ContentItem } from './style';

import { Text } from 'components';

import { Room, ContextLanguage } from 'contexts';
import { selectText } from 'utils/selectTextLang';

import Wow from '../../../../../assets/images/wow_emoji.png';
import Like from '../../../../../assets/images/like_emoji.png';
import Joy from '../../../../../assets/images/joy_emoji.png';
import HeartEyes from '../../../../../assets/images/heart_eyes_emoji.png';

export const Reactions = () => {
  const [active, setActive] = useState(false);
  const [sending, setSending] = useState(false);
  const { reactions: data, sendReaction } = React.useContext(Room.Context);

  const cxtLang = useContext(ContextLanguage);

  const handleOnSendReaction = async value => {
    setSending(true);

    if (!sending) {
      setActive(value);

      await sendReaction({
        reaction: value,
      });
    }

    setTimeout(() => {
      setSending(false);
      setActive('');
    }, 8000);
  };

  const renderInfo = (type, data) => {
    switch (type) {
      case 'like':
        return {
          image: Like,
          count:
            data === 0
              ? selectText(cxtLang.lang, '', '', '')
              : data === 1
              ? `${data} ${selectText(cxtLang.lang, '', '', '')}`
              : `${data} ${selectText(cxtLang.lang, '', '', '')}`,
        };

      case 'heartEyes':
        return {
          image: HeartEyes,
          count:
            data === 0
              ? selectText(cxtLang.lang, '', '', '')
              : data === 1
              ? `${data} ${selectText(cxtLang.lang, '', '', '')}`
              : `${data} ${selectText(cxtLang.lang, '', '', '')}`,
        };

      case 'wow':
        return {
          image: Wow,
          count:
            data === 0
              ? selectText(cxtLang.lang, '', '', '')
              : data === 1
              ? `${data} ${selectText(cxtLang.lang, '', '', '')}`
              : `${data} ${selectText(cxtLang.lang, '', '', '')}`,
        };

      case 'joy':
        return {
          image: Joy,
          count:
            data === 0
              ? selectText(cxtLang.lang, '', '', '')
              : data === 1
              ? `${data} ${selectText(cxtLang.lang, '', '', '')}`
              : `${data} ${selectText(cxtLang.lang, '', '', '')}`,
        };

      default:
        return { image: Like, count: 0 + '' };
    }
  };

  return (
    <Container>
      {Object.keys(data || {}).map(item => (
        <ContentItem
          onClick={() => {
            handleOnSendReaction(item);
          }}
          used={active === item}
          block={sending}>
          <Image src={renderInfo(item, data[item]).image} alt="" />
          <Text size={15}>{renderInfo(item, data[item]).count}</Text>
        </ContentItem>
      ))}
    </Container>
  );
};
