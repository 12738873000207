import React, { useContext, useEffect } from 'react';
import { Button } from '@ederzadravec/jade-ui';
import { useHistory } from 'react-router-dom';

import { Text, Space } from 'components';
import { ContextLanguage } from 'contexts';
import { wellcome } from './Texts';

import { Container, ContainerButton } from './style';

export const Welcome = ({ setAction }) => {
  const history = useHistory();

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(wellcome);
  }, []);

  return (
    <Container>
      <Text type="h" color="dark" center bold="500">
        {cxtLang.texts[cxtLang.lang].title}
      </Text>

      <Space mt={2} />

      <Text type="h" size="16" color="dark" center>
        {cxtLang.texts[cxtLang.lang].descripition}
      </Text>

      <Space mt={3} />

      <ContainerButton>
        {/* <Button color="primary" onClick={() => setAction('register')}>
          1º ACESSO
        </Button> */}

        <Button
          center
          color="primary"
          onClick={() => setAction('registerConfirm')}
        >
          {cxtLang.texts[cxtLang.lang].labelButtonRegister}
        </Button>

        <Space ml={1} />

        <Button color="primary" onClick={() => setAction('login')}>
          {cxtLang.texts[cxtLang.lang].labelButtonLogin}
        </Button>
      </ContainerButton>

      <Space mt={2} />
    </Container>
  );
};
