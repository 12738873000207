export const textsFormRecover = {
  es: {
    labelFieldEmail: 'E-mail',
    labelButton: 'Reinicializar',
    uriBackToLogin: 'Volver a iniciar sesión',
    fieldEmpty: 'Campo Obligatorio',
    successMessage: '¡Se ha enviado una nueva contraseña a su correo electrónico de registro!',
  },
  en: {
    labelFieldEmail: 'E-mail',
    labelButton: 'Reset',
    uriBackToLogin: 'Back to login',
    fieldEmpty: 'Required field',
    successMessage: 'A new password has been sent to your registration email!',
  },
  pt: {
    labelFieldEmail: 'E-mail',
    labelButton: 'Redefinir',
    uriBackToLogin: 'Volte ao login',
    fieldEmpty: 'Campo Obrigatório',
    successMessage:
      'Uma nova senha será enviada ao seu email dentro de alguns minutos! (Por favor,  verifique também sua caixa de spam.)',
  },
};
