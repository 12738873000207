import React, { createContext, useState } from 'react';

const ContextLanguage = createContext();

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState('pt');
  const [texts, setTexts] = useState({
    es: {
      labelButton: 'Entrar',
    },
    en: {
      labelButton: 'Login',
    },
    pt: {
      labelButton: 'Entrar',
    },
  });

  return (
    <ContextLanguage.Provider value={{ setLang, lang, setTexts, texts }}>
      {children}
    </ContextLanguage.Provider>
  );
};

export { ContextLanguage, LanguageProvider };
