import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background: ${({ theme }) => theme.palette.white.main};
  border-bottom: 0px solid ${({ theme }) => theme.palette.dark.light};
  margin-bottom: 16px;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
`;

export const ContentTitle = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentDescription = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentActions = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`;
