import React, { useContext } from 'react';
import { Space } from '@ederzadravec/jade-ui';
import { Box, Text } from 'components';
import styled from 'styled-components';
import { FormSponsors } from './components';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Box>
        <Text type="h" size="16" color="primary" bold="500">
          {cxtLang.lang === 'pt' &&
            'Para garantir que você terá acesso ao estande condizente com a sua categoria profissional, favor declare a informação abaixo:'}
          {cxtLang.lang === 'en' &&
            'To ensure that you will have access to the booth that matches your professional category, please state the information below:'}
          {cxtLang.lang === 'es' &&
            'Para asegurarse de que tendrá acceso al stand que corresponda a su categoría profesional, indique la información a continuación:'}
        </Text>

        <Space top={2} />

        <Text type="h" size="16" color="dark">
          {cxtLang.lang === 'pt' &&
            ' Declaro que sou cirurgião dentista, farmacêutico, médico ou médico veterinário e assim, profissional prescritor ou dispensador de medicamentos, conforme diretrizes da RDC no. 96/08 da ANVISA e o Código de meu Conselho Profissional.'}
          {cxtLang.lang === 'en' &&
            'I declare that I am a dentist, pharmacist, doctor or veterinarian and so, professional prescribing or dispensing drugs, according to guidelines of RDC no. 96/08 of ANVISA and the Code of my Professional Council.'}
          {cxtLang.lang === 'es' &&
            'Declaro que soy dentista, farmacéutico, médico o veterinario y, por tanto, prescripción profesional o dispensación de medicamentos, de acuerdo con las directrices de RDC no. 96/08 de ANVISA y el Código de mi Consejo Profesional.'}
        </Text>

        <Space top={2} />

        <FormSponsors />

        <Text type="h" size="12" color="dark">
          {cxtLang.lang === 'pt' &&
            'Em concordância com a LGPD (Lei Geral de Proteção de Dados), a Política de Privacidade do A.C.Camargo Cancer Center e os termos de uso deste site, informamos que os dados inseridos nessa tela serão utilizados apenas para fins de validação de acesso aos estandes.'}
          {cxtLang.lang === 'en' &&
            'In accordance with the LGPD (General Data Protection Law), the A.C.Camargo Cancer Center Privacy Policy and the terms of use of this website, we inform you that the data entered on this screen will be used only for the purpose of validating access to the booths.'}
          {cxtLang.lang === 'es' &&
            'De acuerdo con la LGPD (Ley General de Protección de Datos), la Política de Privacidad de ACCamargo Cancer Center y las condiciones de uso de este sitio web, le informamos que los datos ingresados ​​en esta pantalla serán utilizados únicamente con la finalidad de validar el acceso al cabinas.'}
        </Text>

      </Box>
    </Container>
  );
};
