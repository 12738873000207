import React, { useContext } from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { ContextLanguage } from 'contexts';

import { Box, Text, Space, Pagination } from 'components';
import { CardBestSale } from './CardBestSale';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ListBests = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export default ({ history }) => {
  const cxtLang = useContext(ContextLanguage);

  const [viewersData, { loading }, refreshViewers] = useService(
    'get',
    '/viewer/detach',
    {
      page: getQuery(location.search)?.page || 1,
      limit: 250,
    },
    true,
    [location.search]
  );

  const handleOnPaginate = page => {
    history.push({ search: `page=${page}` });
  };

  if (viewersData?.success?.viewer?.data.length === 0) {
    return (
      <Container>
        <Box>
          <Text type="h" size="24" bold color="black">
            {cxtLang.lang === 'pt' && 'Em breve aqui, os campeões de vendas de 2023!'}
            {cxtLang.lang === 'en' && 'Coming soon here, the 2023 bestsellers!'}
            {cxtLang.lang === 'es' && '¡Próximamente aquí, los más vendidos de 2023!'}
          </Text>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container>
        <Box>
          <Text type="h" size="24" bold color="black">
            {cxtLang.lang === 'pt' && 'Campeões de vendas'}
            {cxtLang.lang === 'en' && 'Sales champions'}
            {cxtLang.lang === 'es' && 'Campeones de ventas'}
          </Text>

          <Space mt={3} />

          <Text type="p" size="16" color="dark">
            {cxtLang.lang === 'pt' && 'Confira os campeões de vendas de 2023!'}
            {cxtLang.lang === 'en' && 'Check out the 2023 bestsellers!'}
            {cxtLang.lang === 'es' && '¡Mira los más vendidos de 2023!'}
          </Text>

          <Loader direction="column" show={loading}>
            <ListBests>
              {viewersData?.success?.viewer?.data.map((viewer, key) => (
                <CardBestSale key={key} data={viewer} />
              ))}
            </ListBests>
          </Loader>

          <Pagination
            total={viewersData?.success?.viewer?.total?.total}
            page={viewersData?.success?.viewer?.page}
            limit={viewersData?.success?.viewer?.limit}
            onChange={handleOnPaginate}
          />
        </Box>
        <br />
      </Container>
    );
  }
};
