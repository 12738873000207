import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FiBell } from 'react-icons/fi';

import { Item } from './Item';
import { Text } from '../../Text';
import { Toastr } from '../../Toastr/Toastr';
import { useService } from 'service';

import { Store } from 'contexts';
import { ContextLanguage } from 'contexts';
import { useNotificationContext } from 'contexts';

const Container = styled.div`
  position: relative;
`;

const ContainerBell = styled.div`
  width: 40px !important;
  min-width: 40px !important;
  height: 40px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const IconBell = styled(FiBell)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.palette.white.main};
`;

const Count = styled(Text)`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  text-align: center;
  background: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.white.main};
  color: ${({ theme }) => theme.palette.white.main};
  border-radius: 50%;
`;

const ListsNotifications = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  width: 300px;
  position: absolute;
  top: 60px;
  right: -16px;
  min-height: 80px;
  max-height: 600px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.16);
`;

const ItemNull = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const Notifications = () => {
  const [viewed, setViewed] = React.useState(0);
  const { user } = React.useContext(Store.Context);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [{ notifications, totalNotRead }, setNotifications] = React.useState({
    totalNotRead: 0,
    notifications: [],
  });

  const cxtLang = useContext(ContextLanguage);
  const { date } = useNotificationContext();

  const [notificationsData, {}, fetchNotify] = useService(
    'get',
    `/notification/not-read`,
    {},
    true
  );

  const notificationsFilter = notifications
    .filter(item => {
      return !item.link;
    })
    .sort((x, y) => y._id - x._id);

  React.useEffect(() => {
    fetchNotify();
  }, [date]);

  // React.useEffect(() => {
  //   const interval = setInterval(() => fetchNotify(), 15000);
  //   return () => clearInterval(interval);
  // }, []);

  React.useEffect(() => {
    if (notificationsData?.success?.status !== 'OK') return;

    const data = notificationsData?.success?.notifications?.data || [];

    const notRead = data.filter(itens => {
      return !itens?.isRead.filter(item => {
        return item.viewer === user?.id;
      }).length;
    });

    notRead
      .filter(item => !notifications.find(old => old._id === item._id))
      .forEach((item, key) =>
        setTimeout(
          () =>
            Toastr.open({
              title: item.content,
              labelButtom: item.link ? 'IR' : 'Fechar',
              link: item,
            }),
          key * 3000
        )
      );

    setNotifications({
      notifications: data,
      totalNotRead: notRead.length - viewed,
    });
  }, [notificationsData]);

  React.useEffect(() => {
    setNotifications(prev => ({
      ...prev,
      totalNotRead: totalNotRead - viewed,
    }));
  }, [viewed]);

  return (
    <Container>
      <ContainerBell onClick={() => setShowNotifications(!showNotifications)}>
        {totalNotRead > 0 && <Count bold="500">{totalNotRead}</Count>}
        <IconBell />
      </ContainerBell>

      {showNotifications && (
        <ListsNotifications>
          {notificationsFilter.map(item => (
            <Item data={item} setViewed={() => setViewed(prev => prev + 1)} viewed={viewed} />
          ))}

          {!notificationsFilter.length && (
            <ItemNull>
              {cxtLang.lang === 'pt' && 'Você não possui notificações.'}
              {cxtLang.lang === 'es' && 'No tienes notificaciones.'}
              {cxtLang.lang === 'en' && "You don't have notifications."}
            </ItemNull>
          )}
        </ListsNotifications>
      )}
    </Container>
  );
};
