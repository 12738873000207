import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';

import { Text, ButtonDelete } from 'components';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

export default ({ history }) => {
  const [bannerData, { loading }, refreshBanners] = useService('get', '/banner');

  const BASE_URL = '/admin/banner';

  const configList = [
    {
      name: 'Banner',
      dataPath: 'name',
      size: {
        md: 9,
      },
    },
    {
      name: 'Ações',
      dataPath: data => {
        return (
          <>
            <LinkAction type="link" bold onClick={() => history.push(`${BASE_URL}/${data._id}`)}>
              Editar
            </LinkAction>

            <ButtonDelete refresh={refreshBanners} name={data.name} route={`/banner/${data._id}`} />
          </>
        );
      },
      size: {
        md: 3,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Banners
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Novo
        </Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList config={configList} data={bannerData?.success?.banner?.data || []} />
        </Content>
      </Loader>
    </Container>
  );
};
