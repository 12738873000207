import React from 'react';
import { Container } from './list.style';
import { ItemList } from './ItemList';

export const List = ({ data }) => {
  return (
    <Container>
      {data?.map((item, key) => (
        <ItemList key={key} data={item} />
      ))}
    </Container>
  );
};
