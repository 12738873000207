import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// export const LabelNotice = styled(Text)`
//   margin-top: 24px;
//   background: #eeeeee;
//   border-radius: 8px;
//   padding: 16px;
// `;