import styled from 'styled-components';
import { hooks, FormGrid, TextInput } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Text, Space, Button } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const validations = {
  currentPassword: [[value => !value, 'Campo obrigatório']],
  newPassword: [[value => !value, 'Campo obrigatório']],
  rePassword: [
    [value => !value, 'Campo obrigatório'],
    [(value, form) => value !== form.newPassword, 'Senhas divergentes'],
  ],
};

export const FormPassword = ({ idUser }) => {
  const [form, onChange] = hooks.useForm({ validations });
  const [, { loading: updatePassLoading }, updatePassUser] = useService(
    'patch',
    `user/${idUser}/password`,
    {},
    false
  );

  const handleOnSubmit = async () => {
    const data = {
      currentPassword: form.getValue('currentPassword'),
      newPassword: form.getValue('newPassword'),
      rePassword: form.getValue('rePassword'),
    };

    const result = await updatePassUser(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Senha Atualizada',
        confirmButtonText: 'Ok',
      });
      form.clear();
    }

    if (result?.error) {
      if (result?.error?.msg) {
        alert(result?.error?.msg);
      }
      console.log('euu', 'Deu ruim');
    }
  };

  const formConfig = [
    [
      {
        schema: 'currentPassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: 'Senha Atual',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'newPassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: 'Insira a Nova Senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'rePassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: 'Confirme a Nova Senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h" size="22" color="primary">
        Senha e Segurança
      </Text>

      <Space mt={2} />

      <hr />

      <Space mt={4} />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={updatePassLoading} color="primary">
          Atualizar Senha
        </Button>
      </FormGrid>
    </Container>
  );
};
