import React from 'react';
import { useParams } from 'react-router-dom';
import { hooks, FormGrid, TextInput, TextArea, Loader, Button } from '@ederzadravec/jade-ui';

import { Space, Modal } from 'components';
import { useService } from 'service';

const validations = {
  namePt: [[value => !value, 'Campo obrigatório']],
};

export const FormHtml = ({ onClose, id_room, BASE_URL }) => {
  let { html: id_html } = useParams();

  const [form, onChange] = hooks.useForm({
    validations,
  });
  const [, { loading: htmlLoading }, fetchHtml] = useService(
    'get',
    `/room/${id_room}/html/${id_html}`,
    {},
    false
  );
  const [, { loading: saveLoading }, saveHtml] = useService(
    'post',
    `/room/${id_room}/html`,
    {},
    false
  );
  const [, { loading: updateLoading }, updateHtml] = useService(
    'patch',
    `/room/${id_room}/html/${id_html}`,
    {},
    false
  );

  React.useEffect(() => {
    if (!id_html) return;

    fetchHtml()
      .then(data => {
        const html = data?.success?.html || [];

        form.setValues({
          namePt: html?.name?.pt,
          nameEn: html?.name?.en,
          nameEs: html?.name?.es,
          htmlPt: html?.html?.pt,
          htmlEn: html?.html?.en,
          htmlEs: html?.html?.es,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const formConfig = [
    [
      {
        schema: 'namePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'htmlPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Codigo HTML Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'htmlEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Codigo HTML En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'htmlEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Codigo HTML Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  const handleOnSave = async () => {
    const data = {
      namePt: form.getValue('namePt'),
      nameEn: form.getValue('nameEn'),
      nameEs: form.getValue('nameEs'),
      htmlPt: form.getValue('htmlPt'),
      htmlEn: form.getValue('htmlEn'),
      htmlEs: form.getValue('htmlEs'),
    };

    const result = id_html ? await updateHtml(data) : await saveHtml(data);

    if (result?.success?.status === 'OK') {
      onClose(true);
    }

    if (result?.error) {
      console.log('euu', 'Deu ruim');
    }
  };

  return (
    <Modal size={{ md: 6 }} onClose={() => onClose(false)} title="Formulário de HTML">
      <Loader show={htmlLoading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSave)}>
          <Space mt={5} />

          <Button loading={updateLoading || saveLoading}>Salvar</Button>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
