import React from 'react';
import styled from 'styled-components';

import { SideBar } from './SideBar';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: row;

  @media (max-width: 768px) {
    min-height: unset;
    position: relative;
  }
`;

const Body = styled.div`
  transition: all ease 0.3s;
  width: calc(100vw - 300px);
  display: flex;
  background-color: #fff;
  padding: 4px 16px 32px 16px;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    padding: 16px 20px;
  }
`;

export const LayoutAdmin = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <Body>{children}</Body>
    </Container>
  );
};
