import styled from 'styled-components';
import { TableList, Loader } from '@ederzadravec/jade-ui';
import { useLocation } from 'react-router-dom';

import { Box, Text, Space } from 'components';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled(Box)`
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const QuestionsSent = () => {
  const location = useLocation();
  const query = getQuery(location.search);

  const [viewerQuestions, { loading: loadingViewersQuestions }] = useService(
    'get',
    '/dashboard/viewers-questions-sended',
    { room: query.room },
    true,
    [query.room]
  );

  const configList = [
    {
      name: 'Pergunta',
      dataPath: 'question',
      size: {
        md: 5,
      },
    },

    {
      name: 'Usuario',
      dataPath: 'viewer.name',
      size: {
        md: 3,
      },
    },

    {
      name: 'Likes',
      dataPath: data => data?.like?.length || 0,
      size: {
        md: 2,
      },
    },
    {
      name: 'Dislikes',
      dataPath: data => data?.unlike?.length || 0,
      size: {
        md: 2,
      },
    },
  ];

  return (
    <Container>
      <Text type="h" bold color="primary">
        Perguntas Enviadas
      </Text>

      <Space mt={2} />

      <Loader show={loadingViewersQuestions}>
        <TableList config={configList} data={viewerQuestions?.success?.questions || []} />
      </Loader>
    </Container>
  );
};
