import { useEffect, useState } from "react";
import {
  Container,
  Content,
  Label,
  LabelError,
  Selected,
  ContentIcon,
  Icon,
  ContentOption,
  Option,
} from "./select.style";

export const Select = ({ data, onChange, value, label, error }) => {
  const [selected, setSelected] = useState({ label: "", value: "" });
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (!value) return;

    const findData = data.find((item) => item.value === value);

    if (findData !== undefined) {
      setSelected(findData);
      return;
    }
  }, [data]);

  const handleSelect = (item) => {
    setSelected(item);
    onChange(item);
    setShowOptions(false);
  };

  return (
    <Container>
      <Label error={error}>{label}</Label>

      <Content onClick={() => setShowOptions(!showOptions)} error={error}>
        <Selected>
          {selected.value !== "" ? selected.label : "Selecione"}
        </Selected>
        <ContentIcon error={error}>
          <Icon error={error} />
        </ContentIcon>
      </Content>

      {error && !showOptions && <LabelError>{error}</LabelError>}

      {showOptions && (
        <ContentOption>
          {data?.map((item, key) => (
            <Option
              key={key}
              value={item.value}
              onClick={() => handleSelect(item)}
            >
              {item.label}
            </Option>
          ))}
        </ContentOption>
      )}
    </Container>
  );
};
