import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 14px;
`;

export const Content = styled.div`
  padding: 16px 32px 32px 32px;
`;

export const ContentDescription = styled.div``;

export const ContainerFullContent = styled.div`
  font-size: 16px;
`;
