import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { FormQuestion } from 'components';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const Footer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default ({ history, location, match }) => {
  const [topicsData, { loading }, refreshTopics] = useService(
    'get',
    `/room/${match.params.room}/question-topic`
  );

  const BASE_URL = `/admin/room/details/${match?.params?.room}/question-topic`;

  const configList = [
    {
      name: 'Nome',
      dataPath: 'name.pt',
      size: {
        md: 12,
      },
    },
  ];

  const handleNewItem = () => {
    history.push(`${BASE_URL}/new`);
  };

  const handleSelectItem = item => {
    history.push(`${BASE_URL}/${item._id}`);
  };

  const handleFormClose = refresh => {
    history.push(BASE_URL);

    if (refresh) refreshTopics();
  };

  return (
    <Container>
      <Content>
        <Loader show={loading}>
          <TableList
            config={configList}
            data={topicsData?.success?.topics?.data || []}
            onSelect={handleSelectItem}
          />
        </Loader>

        <Footer>
          <Button onClick={handleNewItem}>Novo Tópico</Button>
        </Footer>
      </Content>

      <Switch>
        <Route
          path={[`${BASE_URL}/new`, `${BASE_URL}/:topic`]}
          component={props => (
            <FormQuestion
              {...props}
              onClose={handleFormClose}
              BASE_URL={BASE_URL}
              id_room={match?.params?.room}
            />
          )}
        />
      </Switch>
    </Container>
  );
};
