import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hooks } from '@ederzadravec/jade-ui';

import api from 'service';

const StoreContext = React.createContext({});

const Provider = ({ children }) => {
  const history = useHistory();

  const [{ loading, ...state }, setState] = hooks.useState({ loading: true });

  React.useEffect(() => {
    const getSession = async () => {
      const data = await localStorage.getItem('session');

      if (!data) {
        setState({ loading: false });
        return null;
      }

      const session = JSON.parse(data);

      const headers = { Authorization: session.session, type: session.type };

      const url = session.type === 'VIEWER' ? `/app/session` : '/session';

      const result = await api.get(url, { headers });

      if (result?.data?.error?.status === 'ERROR') {
        return history.push('/logout');
      }

      setState({ ...session, user: result.data.success.user, loading: false });
    };

    getSession();
  }, []);

  const setSession = async session => {
    setState(session);
    await localStorage.setItem('session', JSON.stringify(session));
  };

  const value = {
    ...state,
    setStore: (...props) => setState(...props),
    setSession: (...props) => setSession(...props),
  };

  if (loading) return null;

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const Store = {
  Context: StoreContext,
  Provider,
  Consumer: StoreContext.Consumer,
};
