import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useService } from 'service';
import { Text, Search, Space } from 'components';
import { Info } from './Info';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ({ history }) => {
  let { id } = useParams();

  const [data, { loading }, refreshData] = useService('get', `viewer/${id}`, {}, true, [id]);

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="primary">
          {data?.success?.viewer?.name}
        </Text>

        <Space ml={8} />

        <div style={{ display: 'flex' }}>
          <Search
            routeSearch={'/viewer/find-by-term'}
            typeTerm={'name'}
            routeNavigation={'/admin/viewer'}
            placeholder={'Pesquisar por nome...'}
            responseTerm={'viewer'}
          />

          <Space ml={2} />

          <Search
            routeSearch={'/viewer/find-by-term'}
            typeTerm={'email'}
            routeNavigation={'/admin/viewer'}
            placeholder={'Pesquisar por email...'}
            responseTerm={'viewer'}
          />
        </div>
      </Header>

      <Space mt={2} />

      <hr />

      <Info viewerData={data} refreshData={refreshData} />
    </Container>
  );
};
