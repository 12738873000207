import React from 'react';
import axios from 'axios';
import 'dotenv';

import { hooks } from '@ederzadravec/jade-ui';
import { Store } from 'contexts';

export const baseURL = process.env.API_HOST;

console.log({ baseURL });

const service = axios.create({
  baseURL,
  timeout: 60000,
});

service.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.error?.status === 'NOT_AUTHORIZED') {
      localStorage.removeItem('session');
      return (window.location.href = '/logout');
    }

    return Promise.reject(error);
  }
);

export default service;

export const useService = (type, route, data = {}, autoFetch = true, update) => {
  const { session, type: sessionType } = React.useContext(Store.Context);

  return hooks.useRequest(
    (params = {}, headers = {}) => {
      if (['get', 'options', 'head', 'delete'].includes(type)) {
        return service[type](route, {
          headers: { authorization: session, type: sessionType, ...headers },
          params: autoFetch ? data : params,
        });
      }
      return service[type](route, autoFetch ? data : params, {
        headers: { authorization: session, type: sessionType, ...headers },
      });
    },
    autoFetch,
    update
  );
};
