import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '@ederzadravec/jade-ui';

import { Modal, Text } from 'components';

import { ContextLanguage } from 'contexts';

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 15px;
  padding: 24px;
  margin-top: 24px;
  max-height: 400px;
  overflow: auto;
`;

const ContainerButtom = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const ModalDetail = ({ onClose, dataRoom: data }) => {
  const cxtLang = useContext(ContextLanguage);
  const history = useHistory();

  const html = data.description[cxtLang.lang];
  return (
    <Modal onClose={() => onClose()} style={{ flexDirection: 'column' }}>
      <Text type="h" color="primary" center bold size="26">
        {data.title[cxtLang.lang]}
      </Text>

      <Text type="h" color="primary" center size="24">
        {cxtLang.lang === 'pt' && 'Programação'}
        {cxtLang.lang === 'en' && 'Schedule'}
        {cxtLang.lang === 'es' && 'Horários'}
      </Text>

      <Content dangerouslySetInnerHTML={{ __html: html }} />

      <ContainerButtom>
        {data.isClosed === true && data.isOnline === false && (
          <Button
            disabled
            color="primary"
            style={{ maxWidth: '152px' }}
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'ENCERRADO'}
            {cxtLang.lang === 'en' && 'CLOSED'}
            {cxtLang.lang === 'es' && 'CERRADO'}
          </Button>
        )}

        {data.isOnline === false && data.isClosed === undefined && (
          <Button style={{ maxWidth: '152px' }} color="primary">
            {cxtLang.lang === 'pt' && 'EM BREVE'}
            {cxtLang.lang === 'en' && 'COMING SOON'}
            {cxtLang.lang === 'es' && 'EN BREVE'}
          </Button>
        )}

        {data.isOnline === true && (
          <Button
            style={{ maxWidth: '152px' }}
            color="primary"
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'ASSISTIR'}
            {cxtLang.lang === 'en' && 'WATCH'}
            {cxtLang.lang === 'es' && 'VER'}
          </Button>
        )}
      </ContainerButtom>
    </Modal>
  );
};
