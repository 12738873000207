import React from 'react';
import styled from 'styled-components';
import {
  hooks,
  FormGrid,
  TextInput,
  Button,
  TextArea,
  Loader,
  PhotoUpload,
} from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { useService } from 'service';
import { dateToDb, dbToDate } from 'utils/date';
import { fileToBase64, validFile } from 'utils/file';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const validations = {
  titlePt: [[value => !value, 'Deve ser preenchido']],
  image: [
    [
      value => value && value?.length && !validFile(value[0], ['png', 'jpeg', 'jpg']),
      'Arquivo não permitido',
    ],
  ],
};

export const FormHeader = ({ history }) => {
  const [dataHeader, { loading }, fetchHeader] = useService(
    'get',
    `/header/60a5c413e3d43d1758697904`
  );
  const [, { loading: loadingHeader }, updateHeader] = useService(
    'patch',
    `/header/60a5c413e3d43d1758697904`,
    {},
    false
  );

  const header = dataHeader?.success?.header;

  React.useEffect(() => {
    if (!header?._id) return;

    const image = header?.image ? [header?.image] : []; // transformar string em array

    form.setValues({
      ...header,

      titlePt: header.title.pt,
      titleEn: header.title.en,
      titleEs: header.title.es,

      subTitlePt: header.subTitle.pt,
      subTitleEn: header.subTitle.en,
      subTitleEs: header.subTitle.es,

      resumePt: header.resume.pt,
      resumeEn: header.resume.en,
      resumeEs: header.resume.es,

      contentPt: header.content.pt,
      contentEn: header.content.en,
      contentEs: header.content.es,

      image,
      //dateStart: dbToDate(header?.dateStart, 'dd/MM/yyyy'),
      //dateFinish: dbToDate(header?.dateFinish, 'dd/MM/yyyy'),
      dateStart: header?.dateStart,
      dateFinish: header?.dateFinish,
    });
  }, [dataHeader]);

  const BASE_URL = '/admin/header';
  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmit = async () => {
    const image = form.getValue('image');
    const photoBase64 = image && image.length ? await fileToBase64(image[0]) : null;

    const data = {
      titlePt: form.getValue('titlePt'),
      titleEn: form.getValue('titleEn'),
      titleEs: form.getValue('titleEs'),

      subTitlePt: form.getValue('subTitlePt'),
      subTitleEn: form.getValue('subTitleEn'),
      subTitleEs: form.getValue('subTitleEs'),

      //dateStart: dateToDb(form.getValue('dateStart'), 'dd/MM/yyyy'),
      //dateFinish: dateToDb(form.getValue('dateFinish'), 'dd/MM/yyyy'),
      dateStart: form.getValue('dateStart'),
      dateFinish: form.getValue('dateFinish'),

      resumePt: form.getValue('resumePt'),
      resumeEn: form.getValue('resumeEn'),
      resumeEs: form.getValue('resumeEs'),

      contentPt: form.getValue('contentPt'),
      contentEn: form.getValue('contentEn'),
      contentEs: form.getValue('contentEs'),
      image: photoBase64,
    };

    const result = await updateHeader(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Dados Atualizados',
        confirmButtonText: 'Ok',
      });

      history.push(BASE_URL);
    }

    if (result?.error) {
      console.log('euu', 'Deu Ruim');
    }
  };

  const formConfig = [
    [
      {
        schema: 'titlePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'subTitlePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Sub Titulo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'subTitleEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Sub Titulo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'subTitleEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Sub Titulo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'dateStart',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Data início',
          //variant: 'date',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'dateFinish',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Data fim',
          //variant: 'date',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'resumePt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resumo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'resumeEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resumo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'resumeEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resumo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'contentPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Conteúdo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'contentEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Conteúdo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'contentEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Conteúdo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'image',
        size: { md: 12 },
        type: PhotoUpload,
        props: schema => ({
          label: 'Imagem',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Loader show={loading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <Button loading={loadingHeader}>Salvar</Button>
        </FormGrid>
      </Loader>
    </Container>
  );
};
