import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LogoSVG from '../../../assets/images/brand.svg';
import { Store } from 'contexts';
import { Text } from '../../Text';

import { ContextLanguage } from 'contexts';
import { homeTexts } from '../Texts/HomeTexts';

import { Notifications } from '../notifications/Notifications';
import { Search } from '../Search';
import { Flags } from '../Flags';

import * as S from './style';

export const MenuDesktop = ({ setSideActive }) => {
  const history = useHistory();
  const { user } = React.useContext(Store.Context);

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(homeTexts);
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.LeftHeader>
          <S.ContaintToggle onClick={setSideActive}>
            <S.Toggle />
          </S.ContaintToggle>

          <S.Logo onClick={() => history.push('/app')} src={LogoSVG} />
        </S.LeftHeader>

        <S.CenterHeader>
          <S.Wellcome>
            <Text size="20" color="secondary" bold="500">
              <Text size="20" color="black">
                {cxtLang.texts[cxtLang.lang].navBarTextHI}
              </Text>
              {user?.name}
            </Text>
          </S.Wellcome>
        </S.CenterHeader>

        <S.RightHeader>
          {/* <Search /> */}

          <Flags />

          {/* <Separator />

          <Notifications /> */}

          <S.Separator />

          <S.ImageProfile src={user?.photo} />
        </S.RightHeader>
      </S.Header>
    </S.Container>
  );
};
