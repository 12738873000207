import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, FormGrid, hooks, TextInput, PhotoUpload } from '@ederzadravec/jade-ui';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Button, Space, Text } from 'components';
import { useService } from 'service';
import { Store } from 'contexts';
import { fileToBase64, validFile } from 'utils/file';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  padding: 24px 0;
`;

const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const validations = {
  name: [[value => !value, 'Campo obrigatório']],
  email: [[value => !value, 'Campo obrigatório']],
  companyUnit: [[value => !value, 'Campo obrigatório']],
  companyPosition: [[value => !value, 'Campo obrigatório']],
  photo: [
    [value => !value, 'É obrigatório enviar a sua foto!'],
    [
      value => value.length && !validFile(value[0], ['png', 'jpeg', 'jpg']),
      'Arquivo não permitido',
    ],
  ],
  password: [[value => !value, 'Campo obrigatório']],
  rePassword: [
    [value => !value, 'Campo obrigatório'],
    [(value, form) => value !== form.password, 'Senhas divergentes'],
  ],
};

const ContentTerm = styled.div`
  display: flex;
  align-items: center;
`;

const Checked = styled.input`
  margin: 5px 0 0 5px;
  float: right;
  cursor: pointer;
`;

const Link = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.dark.main};
  font-weight: bold;
`;

const Footer = styled(Text)`
  width: 100%;
  margin-top: 24px;
  margin-bottom: -24px;
  color: #bbbbbb;
`;

const FormTitle = ({ label }) => (
  <TitleForm>
    <Text type="h" size="22" color="primary" bold="500">
      {label}
    </Text>
    <Space mt={1} />
    <hr />
    <Space mt={1} />
  </TitleForm>
);

export const Form = () => {
  const [acceptTerms, setAcceptTerms] = React.useState(true);
  const { setSession, setStore } = React.useContext(Store.Context);

  const params = useParams();
  const history = useHistory();

  const cxtLang = useContext(ContextLanguage);

  const [, { loading }, saveViewer] = useService(
    'post',
    `/app/viewer/register/${params.register}`,
    {},
    false
  );
  const [, {}, getViewer] = useService('get', `/app/viewer/register/${params.register}`, {}, true);
  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    getViewer()
      .then(data => {
        form.setValues(data?.success?.viewer);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const Alert = () => {
    Swal.fire({
      title: 'Por favor, insira uma foto para prosseguir com o seu cadastro.',
      confirmButtonText: 'Ok',
    });
  };

  const handleOnSubmit = async () => {
    if (form.getValue('photo').length === 0) {
      Alert();

      return;
    }

    const photo = form.getValue('photo');
    const photoBase64 = photo && photo.length ? await fileToBase64(photo[0]) : null;

    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      companyUnit: form.getValue('companyUnit'),
      companyPosition: form.getValue('companyPosition'),
      password: form.getValue('password'),
      rePassword: form.getValue('rePassword'),
      photo: photoBase64,
    };

    const result = await saveViewer(data);

    if (result?.success?.status === 'OK') {
      setStore({
        user: result.success.user,
      });

      setSession({
        session: result.success.token,
        type: result.success.user.type,
      });
      return history.push('/app');
    }

    if (result?.error) {
      form.setError(result.error);
    }
  };

  const handleChange = e => {
    setAcceptTerms(!acceptTerms);
  };

  const formConfig = [
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => <FormTitle label={cxtLang.texts[cxtLang.lang].formSectionPersonal} />,
      },
    ],
    [
      {
        schema: 'name',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelName}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'email',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelEmail}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'companyUnit',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelUnity}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'companyPosition',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelOffice}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => (
          <TitleForm>
            <Space mt={1} />
            <Text type="h" size="18" color="primary" bold="500">
              {cxtLang.texts[cxtLang.lang].textAddImage}
            </Text>
            <Space mt={-3} />
          </TitleForm>
        ),
      },
      {
        schema: 'photo',
        size: { md: 12, xs: 12 },
        type: PhotoUpload,
        props: schema => ({
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => <FormTitle label={cxtLang.texts[cxtLang.lang].textPassword} />,
      },
    ],
    [
      {
        schema: 'password',
        size: { xs: 12, md: 6 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: `${cxtLang.texts[cxtLang.lang].labelPassword}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'rePassword',
        size: { xs: 12, md: 6 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: `${cxtLang.texts[cxtLang.lang].labelConfirmationPassword}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Grid container>
        <Grid size={{ xs: 12, md: 12 }}>
          <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
            <Space mt={1} />

            <ContentTerm>
              <Text size="16" color="dark">
                {cxtLang.texts[cxtLang.lang].textTermsUse}
                <Link href={cxtLang.texts[cxtLang.lang].uriTermUser} target="_blank">
                  {cxtLang.texts[cxtLang.lang].textUriTermsUse}
                </Link>{' '}
                <Checked type="checkbox" name="aceptTerms" onChange={handleChange} />
              </Text>
            </ContentTerm>

            <Space mt={2} />

            <Button disabled={acceptTerms} loading={loading}>
              {cxtLang.texts[cxtLang.lang].buttonAccess}
            </Button>
          </FormGrid>
        </Grid>
      </Grid>

      <Footer type="p" size="14" center bold="500">
        {cxtLang.texts[cxtLang.lang].titleFooter}
      </Footer>
    </Container>
  );
};
