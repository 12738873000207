import { Switch, Route } from 'react-router-dom';

import List from './List';
//import Wall from './viewer/Viewer';

export default () => {
  const BASE_URL = '/admin/wall';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`, `${BASE_URL}/new`]} component={List} exact />
      {/* <Route path={`${BASE_URL}/:id`} component={Viewer} /> */}
    </Switch>
  );
};
