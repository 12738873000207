import React, { useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Loader } from '@ederzadravec/jade-ui';

import { Text, Space, Button } from 'components';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  margin: 16px 0 32px;
  width: 100%;
`;

const Alternative = styled.div`
  width: 100%;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;

  ${({ correct }) => (correct ? 'background:#dfffb4 ;' : 'background: #ffa9a9;')}
`;

export const ListQuizzeResume = ({ idQuiz }) => {
  const { room: idRoom } = useParams();

  const [dataQuizzes, { loading }, fetchQuestions] = useService(
    'get',
    `/room/${idRoom}/quiz/${idQuiz}/result`,
    {},
    true,
    [idQuiz]
  );

  const data = dataQuizzes?.success || [];

  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Text size="22" color="dark">
        {data?.description?.[cxtLang.lang]}
      </Text>

      <Space mt={2} />

      <Loader show={loading} direction="column">
        {data?.quiz?.map(item => (
          <Alternative key={item._id} correct={item.isCorrect}>
            <Text size="16" bold type="h">
              {item.total} {item.total === 1 ? 'respondeu:' : 'responderam:'}{' '}
              <Text size="16">{item.label[cxtLang.lang]}</Text>
            </Text>
            <Space mt={2} />
          </Alternative>
        ))}
      </Loader>

      <Button loading={loading} onClick={() => fetchQuestions()}>
        Atualizar
      </Button>
    </Container>
  );
};
