import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const Content = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

