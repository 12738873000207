import React from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';
import { FaRegBell } from 'react-icons/fa';

import { Text } from '../Text';
import { useService } from 'service';

const Container = styled.div`
  background: #eeeeee;
  border-radius: 20px;
  width: 330px;
  padding: 24px;
  z-index: 1;
  transition: 0.3s;
  margin-top: 16px;
  margin-right: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const IconBell = styled(FaRegBell)`
  font-size: 32px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Item = ({ title, labelButtom, link, onClick, onClose }) => {
  const [, {}, setIsReadNotify] = useService(
    'patch',
    `/notification/${link?._id}/viewer-set-read`,
    {},
    false
  );

  const handleOnClick = async () => {
    onClick();

    if (link) {
      await setIsReadNotify();
      window.open(link?.link, '_self');
    }

    onClose();
  };

  return (
    <Container>
      <Header>
        <ContentIcon>
          <IconBell />
        </ContentIcon>

        <Text type="h" size="18" color="dark" bold="500">
          {title ? title : 'Mensagem'}
        </Text>
      </Header>

      <Button color="primary" onClick={handleOnClick}>
        {labelButtom}
      </Button>
    </Container>
  );
};

Item.defaultProps = {
  onClick: () => {},
  labelButtom: 'Ok',
};
