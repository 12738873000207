import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  ${({ table }) => table ? `
  display: inline-flex;
  margin: 0;
  `: `
  display: flex;
  margin: 5px 0;
  `}
`;

export const Content = styled.div`
  display: inline-flex;
  border: 1px solid ${({ theme }) => theme.palette.dark.main};
  border-radius: 5px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ label }) => label ? `margin-right: 10px;` : ``};
`;


export const Checker = styled.div`
  background: ${({ checked, theme }) => checked ? theme.palette.primary.main : 'transparent'};
  border-radius: 5px;
  width: 8px;
  height: 8px;
  cursor: pointer;
`;
