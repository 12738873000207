import { Switch, Route } from 'react-router-dom';

import List from './List';

export default () => {
  const BASE_URL = '/admin/notification';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`, `${BASE_URL}/:id`, `${BASE_URL}/new`]} component={List} />
    </Switch>
  );
};
