import styled from 'styled-components';
import { hooks, FormGrid, Button, TextInput, TextArea, Space } from '@ederzadravec/jade-ui';

import { Modal } from 'components';
import { useService } from 'service';

const Container = styled(Modal)``;

const validations = {
  name: [[value => !value, 'Deve ser preenchido']],
};

export const Form = ({ onClose }) => {
  const [, { loading }, saveSpeaker] = useService('post', '/speaker', {}, false);
  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      description: form.getValue('description'),
    };

    const result = await saveSpeaker(data);

    if (result?.success?.status === 'OK') {
      return onClose();
    }

    if (result.error.status === 'FORM') {
      return form.setError(result.error);
    }

    // Implementar Toaster
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'description',
        size: { md: 12 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];
  return (
    <Container size={{ md: 6 }} title="Cadastrar Palestrante" onClose={onClose}>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space top={3}>
          <Button>Enviar</Button>
        </Space>
      </FormGrid>
    </Container>
  );
};
