import React, { useContext } from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, TextInput, Button } from '@ederzadravec/jade-ui';

import { Text, Space, Toastr } from 'components';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ContentButton = styled.div`
  width: 200px;
  display: inline-block;
  margin-right: 12px;
`;

const validations = {};

const thisTexts = {
  es: {
    settingsFormSectionPersonal: 'Datos Personales',
    settingsLabelName: 'Nombre',
    settingsLabelEmail: 'E-mail',
    settingsLabelUnity: 'Unidad',
    settingsLabelOffice: 'Oficina',
    settingsButtonSave: 'Ahorrar',
  },
  en: {
    settingsFormSectionPersonal: 'Personal data',
    settingsLabelName: 'Name',
    settingsLabelEmail: 'E-mail',
    settingsLabelUnity: 'Unity',
    settingsLabelOffice: 'office',
    settingsButtonSave: 'Save',
  },
  pt: {
    settingsFormSectionPersonal: 'Dados Pessoais',
    settingsLabelName: 'Nome',
    settingsLabelEmail: 'E-mail',
    settingsLabelUnity: 'Unidade',
    settingsLabelOffice: 'Cargo',
    settingsButtonSave: 'Salvar',
  },
};

export const FormUser = ({ idUser }) => {
  const [form, onChange] = hooks.useForm({ validations });
  
  const cxtLang = useContext(ContextLanguage);
  
  const [, { loading }, fetchViewer] = useService('get', `viewer/${idUser}`, {}, false);
  const [, { loading: updateLoading }, updateViewer] = useService(
    'patch',
    `viewer/${idUser}`,
    {},
    false
    );

  React.useEffect(() => {
    fetchViewer().then(data => {
      if (data?.error) {
        console.log('euu', 'Deu ruim');
      }

      if (data?.success?.status === 'OK') {
        const viewer = data?.success?.viewer;

        form.setValues(viewer);
      }
    });
  }, []);

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      // companyUnit: form.getValue('companyUnit'),
      // companyPosition: form.getValue('companyPosition'),
    };

    const result = await updateViewer(data);

    if (result?.success?.status === 'OK') {
      Toastr.open({
        title: 'Dados atualizados',
        labelButtom: 'Ok',
      });
    }

    if (result?.error) {
      //return <Toastr label="Deu Ruim" />;
      console.log('euu', 'Deu ruim');
    }
  };
 

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${thisTexts[cxtLang.lang].settingsLabelName}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'email',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${thisTexts[cxtLang.lang].settingsLabelEmail}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    // [
    //   {
    //     schema: 'companyUnit',
    //     size: { md: 6, xs: 12 },
    //     type: TextInput,
    //     props: schema => ({
    //       label: `${thisTexts[cxtLang.lang].settingsLabelUnity}`,
    //       value: form.getValue(schema),
    //       error: form.getError(schema),
    //       onChange: (e, value) => onChange(schema)(value),
    //     }),
    //   },
    //   {
    //     schema: 'companyPosition',
    //     size: { md: 6, xs: 12 },
    //     type: TextInput,
    //     props: schema => ({
    //       label: `${thisTexts[cxtLang.lang].settingsLabelOffice}`,
    //       value: form.getValue(schema),
    //       error: form.getError(schema),
    //       onChange: (e, value) => onChange(schema)(value),
    //     }),
    //   },
    // ],
  ];

  return (
    <Container>
      <Text type="h" size="22" color="black" bold="500">
      {thisTexts[cxtLang.lang].settingsFormSectionPersonal}
      </Text>

      <Space mt={2} />

      <hr />

      <Space mt={4} />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <ContentButton>
          <Button loading={updateLoading} color="primary">
          {thisTexts[cxtLang.lang].settingsButtonSave}
          </Button>
        </ContentButton>
      </FormGrid>
    </Container>
  );
};
