import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const ContentInfo = styled.div`
  margin-bottom: 16px;
`;

export const ContentPagesItens = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Item = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
  ${({ active, theme }) =>
    active
      ? `
      background-color: ${theme.palette.primary.main};
      color:${theme.palette.white.main};
    `
      : `
      background-color: ${theme.palette.white.main};
      color:${theme.palette.primary.main};`};
`;

export const ItemLoading = styled.div`
  height: 32px;
`;
