import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';

import { Form } from './Form';
import { Text, Space } from 'components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
  width: inherit;
`;

const ContentDataPersonal = styled.div`
  display: flex;
`;

const ContentPicture = styled.div`
  width: 300px;
`;

const Picture = styled.div`
  background-color: #777;
  width: 300px;
  height: 300px;
  border-radius: 14px;
`;

const Badges = styled.div`
  background-color: #777;
  width: 100%;
  height: 150px;
  border-radius: 14px;
`;

const IconEdit = styled(FaPen)`
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  display: inline-block;
`;

export const Info = ({ userData }) => {
  return (
    <Container>
      <ContentDataPersonal>
        <Form userData={userData} />

        <ContentPicture>
          <Text type="h" size="18" color="dark" bold="500">
            Foto Perfil {'  '} <IconEdit />
          </Text>

          <Space mt={2} />

          <Picture />
        </ContentPicture>
      </ContentDataPersonal>

      <Space mt={2} />

      <Text type="h" size="18" color="dark" bold="500">
        Badges do Usuário:
      </Text>

      <Space mt={2} />

      <Badges />
    </Container>
  );
};
