import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { Text, Space } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

import { ContextLanguage, useNotificationContext } from 'contexts';

const Container = styled.div`
  background: #fff;
  border-radius: 14px;
  margin-bottom: 24px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 14px 14px 0 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 32px;
`;

const ContentTitle = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const DateTheEvent = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const Dates = styled.div``;

const DateStartEnd = styled(Text)``;

const IcomCalendar = styled(FaRegCalendarAlt)`
  font-size: 40px;
  margin-right: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin-bottom: 40px;
`;

const ContainerFullContent = styled.div`
  font-size: 16px;
  width: 100%;
  line-height: 1.5;

  & b {
    font-size: 16px;
  }
`;

export const HeaderPage = () => {
  const [showContent, setShowContent] = React.useState(false);
  const [dataHeader, {}] = useService('get', `/header/60a5c413e3d43d1758697904`);

  const data = dataHeader?.success?.header || [];

  const cxtLang = useContext(ContextLanguage);
  const { handleReq } = useNotificationContext();

  React.useEffect(() => {
    handleReq();
  }, []);

  return (
    <Container>
      <Image src={data?.image} alt="" />

      <Header>
        <ContentTitle>
          <Text type="h" finality="title" color="secondary">
            {data?.title?.[cxtLang.lang]}
          </Text>

          {data?.subTitle && (
            <>
              <Space mt={1} />

              <Text type="h"  size="16" color="dark">
                {data?.subTitle?.[cxtLang.lang]}
              </Text>
            </>
          )}
        </ContentTitle>

        <DateTheEvent>
          <IcomCalendar color="primary" />

          <Dates>
            <DateStartEnd type="p" size="14" color="dark">
              {/* {cxtLang.lang === 'pt' && 'Inicio '}
              {cxtLang.lang === 'en' && 'Starts at '}
              {cxtLang.lang === 'es' && 'Inicio '} */}
              <Text type="span" size="14" color="dark">
                {data?.dateStart}
              </Text>
            </DateStartEnd>

            <DateStartEnd type="p" size="14" color="dark">
              {/* {cxtLang.lang === 'pt' && 'Término '}
              {cxtLang.lang === 'en' && 'Ends at '}
              {cxtLang.lang === 'es' && 'Terminación '} */}
              <Text type="span" size="14" color="dark">
                {data?.dateFinish}
              </Text>
            </DateStartEnd>
          </Dates>
        </DateTheEvent>
      </Header>

      {!showContent ? (
        <Content>
          <ContainerFullContent
            dangerouslySetInnerHTML={{ __html: data?.resume?.[cxtLang.lang] }}
            style={{ fontSize: '16px' }}
          />

          {/* <Space mt={2} />

          <Text
              type="link"
              size="14"
              onClick={() => setShowContent(!showContent)}>
              {cxtLang.lang === 'pt' && '...Continuar Lendo'}
              {cxtLang.lang === 'en' && '...Continue Reading'}
              {cxtLang.lang === 'es' && '...Sigue Leyendo'}
            </Text> */}
        </Content>
      ) : (
        <Content>
          <ContainerFullContent
            dangerouslySetInnerHTML={{ __html: data?.content?.[cxtLang.lang] }}
          />

          <Space mt={2} />
          <Text type="link" size="14" onClick={() => setShowContent(!showContent)}>
            {cxtLang.lang === 'pt' && '...Recolher'}
            {cxtLang.lang === 'en' && '...to Recall'}
            {cxtLang.lang === 'es' && '...Disminuir '}
          </Text>
        </Content>
      )}
    </Container>
  );
};
