import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid, Button } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Space, Modal, Text, Checkbox, ListInstagram } from 'components';
import { Room } from 'contexts';

import { Chat } from './LiveInteractions/Chat';
import { FeaturedMessages } from './LiveInteractions/FeaturedMessages';
import { LiveInteractions } from './LiveInteractions/LiveInteractions';
import { ContentVideo } from './ContentVideo';
import { FormModal } from './FormModal';
import { QrCode } from './QrCode';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';
import { useState } from '@ederzadravec/jade-ui/build/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`;

const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ({ history, match }) => {
  const [language, setLanguage] = React.useState('portuguese');
  const [privacyAgreement, setPrivacyAgreement] = React.useState(false);

  const idRoom = match?.params?.room;

  const [dataRoom, { loading }] = useService('get', `/app/room/${idRoom}`, {}, true, [idRoom]);

  const cxtLang = useContext(ContextLanguage);

  const handleOnToggles = () => {
    language === 'portuguese' && setLanguage('spanish');
    language === 'spanish' && setLanguage('portuguese');
  };

  const handleOnPrivacy = aware => {
    if (!aware) {
      Swal.fire({
        title: `${
          (cxtLang.lang === 'pt' && 'Voce precisa concordar para acessar a sala.') ||
          (cxtLang.lang === 'en' && 'Sorry this room is not available yet.') ||
          (cxtLang.lang === 'es' && 'Lo sentimos, esta habitación aún no está disponible.')
        }`,
        confirmButtonText: 'Ok',
      });
      return;
    }

    setPrivacyAgreement(true);
  };

  React.useEffect(() => {
    if (dataRoom?.success?.status === 'OK' && !dataRoom?.success?.room?.isOnline) {
      Swal.fire({
        title: `${
          (cxtLang.lang === 'pt' && 'Desculpe esta sala ainda não esta disponivel.') ||
          (cxtLang.lang === 'en' && 'Sorry this room is not available yet.') ||
          (cxtLang.lang === 'es' && 'Lo sentimos, esta habitación aún no está disponible.')
        }`,
        icon: 'warning',
        focusConfirm: false,
        confirmButtonColor: '#37B6C4',
        confirmButtonText: `${
          (cxtLang.lang === 'pt' && 'Ir para tela inicial') ||
          (cxtLang.lang === 'en' && 'Go to home screen') ||
          (cxtLang.lang === 'es' && 'ir a la pantalla de inicio')
        }`,
        backdrop: `rgba(0,0,0,0.4)`,
      }).then(result => {
        if (result.isConfirmed) {
          history.push('/app');
        }
      });
      return '';
    }
  }, [dataRoom]);

  return (
    <Room.Provider room={idRoom}>
      {!privacyAgreement && (
        <Modal
          size={{ sm: 12, md: 6, xs: 12 }}
          onClose={() => {
            handleOnPrivacy(false);
          }}>
          <ContentModal>
            <Text color="primary" type="h" size="24" bold="500">
              {cxtLang.lang === 'pt' && `CONFIDENCIALIDADE`}
              {cxtLang.lang === 'en' && `Update`}
              {cxtLang.lang === 'es' && `CONFIDENCIALIDAD`}
            </Text>

            <Space mt={1} />

            <Text color="dark" type="p" size="16">
              {cxtLang.lang === 'pt' &&
                `TODOS os conteúdos transmitidos são CONFIDENCIAIS. É proibido filmar, fotografar e compartilhar as telas. Vamos garantir o sigilo das nossas informações.`}
              {cxtLang.lang === 'en' &&
                `ALL the contents transmitted are CONFIDENTIAL. It is forbidden to film, photograph and share the pictures. We will guarantee the secrecy of our information.`}
              {cxtLang.lang === 'es' &&
                `TODOS los contenidos transmitidos son CONFIDENCIALES. Está prohibido filmar, fotografiar y compartir las pantallas. Vamos a garantizar la confidencialidad de nuestra información.`}
            </Text>

            <Space mt={2} />

            <Button onClick={() => handleOnPrivacy(true)} variant="outline">
              {cxtLang.lang === 'pt' && `ESTOU CIENTE E CONCORDO`}
              {cxtLang.lang === 'en' && `UpdI AM AWARE AND AGREEate`}
              {cxtLang.lang === 'es' && `ESTOY INFORMADO Y DE ACUERDO`}
            </Button>
          </ContentModal>
        </Modal>
      )}

      <Container>
        <Grid container>
          <Grid size={{ xs: 12, md: 8.5 }}>
            <ContentVideo idRoom={idRoom} />

            {/* <FeaturedMessages idRoom={idRoom} /> */}
            <LiveInteractions
              language={language}
              handleOnToggles={handleOnToggles}
              idRoom={idRoom}
            />

            <ListInstagram />
          </Grid>

          <Grid size={{ xs: 12, md: 3.5 }}>
            <Chat idRoom={idRoom} />
            {/* <Grid size={{ xs: 12, md: 3.5 }}>
            <QrCode />
          </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </Room.Provider>
  );
};
