import React from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { Text, Box, Space } from 'components';
import { GroupRooms } from './GroupRooms';
import { CardRoom } from './CardRoom';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BoxHeader = styled(Box)`
  margin-bottom: 24px;
`;

export default () => {
  const [dataSchedules, { loading }, refreshSchedules] = useService('get', 'app/schedule');

  const schedules = dataSchedules?.success?.schedules?.data || [];

  const sortedSchedules = schedules.sort((x, y) => x.room.openAt - y.room.openAt);

  return (
    <Container>
      <BoxHeader>
        <Text type="h" size="24" bold color="primary">
          MINHA AGENDA
        </Text>

        <Space mt={2} />

        <Text type="p" size="16" color="dark">
          Nesta área, você confere os temas que escolheu para assistir. É a sua agenda personalizada
          que garante o acesso rápido às palestras da convenção.
        </Text>
      </BoxHeader>

      <Loader show={loading}>
        <GroupRooms data={sortedSchedules} refreshList={refreshSchedules} />
      </Loader>
    </Container>
  );
};
