import { Switch, Route } from 'react-router-dom';

import List from './List';
import Speaker from './speaker/Speaker';

export default () => {
  const BASE_URL = '/admin/speaker';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`, `${BASE_URL}/new`]} component={List} exact />
      <Route path={`${BASE_URL}/:id`} component={Speaker} exat />
    </Switch>
  );
};
