import styled from 'styled-components';
import { FaStar, FaCaretLeft, FaCaretRight } from 'react-icons/fa';

import { Text, Space, Button } from 'components';
import { Form } from './Form';
import { FormPassword } from './FormPassword';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
`;

const ContentDataPersonal = styled.div`
  display: flex;
`;

const ContentPicture = styled.div`
  width: 300px;
`;

const Picture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

export const Info = ({ viewerData, refreshData }) => {
  const [, { loading }, fetchDetachViewer] = useService('patch', `viewer/setdetach`, {}, false);

  const handleOnDetach = async id => {
    const result = await fetchDetachViewer({ id: id });

    if (result?.success?.status === 'OK') {
      refreshData();
    }

    if (result?.error) {
      console.log('euu', 'deu ruimm');
    }
  };

  return (
    <Container>
      <ContentDataPersonal>
        <Form viewerData={viewerData} />

        <ContentPicture>
          <Text type="h" size="18" color="dark" bold="500">
            Foto Perfil
          </Text>

          <Picture src={viewerData?.success?.viewer?.photo} />
        </ContentPicture>
      </ContentDataPersonal>

      <Space mt={2} />

      <FormPassword viewerData={viewerData} />

      <Space mt={2} />

      <Button type="button" onClick={() => handleOnDetach(viewerData?.success?.viewer?._id)}>
        {loading ? (
          'DESTACANDO...'
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
            {viewerData?.success?.viewer?.isDetach ? <FaStar /> : <FaCaretRight />}
            {viewerData?.success?.viewer?.isDetach ? 'DESTACADO' : 'DESTACAR'}{' '}
            {viewerData?.success?.viewer?.isDetach ? <FaStar /> : <FaCaretLeft />}
          </div>
        )}
      </Button>
    </Container>
  );
};
