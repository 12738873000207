import styled from 'styled-components';
import { TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text, ButtonDelete } from 'components';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

const SetVisibility = ({ data, refreshList }) => {
  const [, { loading: loadingSetVisibility }, fetchSetVisibility] = useService(
    'patch',
    `wall/set-visibility/${data._id}`,
    {},
    false
  );

  const handleOnVisibility = async () => {
    await fetchSetVisibility();
    refreshList();
  };

  return (
    <LinkAction type="link" color="dark" onClick={() => handleOnVisibility()}>
      {loadingSetVisibility ? '...' : !data.visible ? 'Publicar' : 'Ocultar'}
    </LinkAction>
  );
};

export default ({ history, location }) => {
  const [wallData, { loading }, refreshWalls] = useService('get', '/wall');

  const BASE_URL = '/admin/wall';

  const configList = [
    {
      name: 'Usuário',
      dataPath: data => data.viewer.name || data.user.name,
      size: {
        md: 2,
      },
    },
    {
      name: 'Comentário',
      dataPath: 'content',
      size: {
        md: 7,
      },
    },
    {
      name: 'Ações',
      dataPath: data => (
        <>
          {/* <LinkAction
            type="link"
            color="dark"
            onClick={() => history.push(BASE_URL + '/' + data._id)}>
            Editar
          </LinkAction> */}

          <SetVisibility refreshList={refreshWalls} data={data} />

          <ButtonDelete name={data.content} refresh={refreshWalls} route={`/wall/${data._id}`} />
        </>
      ),
      size: {
        md: 2,
      },
    },

    {
      name: 'Publico',
      dataPath: data => <>{data.visible ? 'Sim' : 'Não'}</>,
      size: {
        md: 1,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Mural
        </Text>

        {/* <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Novo
        </Button> */}
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList config={configList} data={wallData?.success?.walls?.data || []} />
        </Content>
      </Loader>
    </Container>
  );
};
