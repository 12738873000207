import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Banner } from './banner/Banner';

export default () => {
  const BASE_URL = '/admin/banner';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`]} component={List} exact />
      <Route path={[`${BASE_URL}/:id`, `${BASE_URL}/new`]} component={Banner} />
    </Switch>
  );
};
