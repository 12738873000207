import React, { useState } from 'react';
import { TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text, Search, Pagination, Space } from 'components';
import { useService } from 'service';

import { Form } from '../Form';
import { CompleteForm } from '../CompleteForm';
import { getQuery } from 'utils/url';

import { Button, Container, Content, ContentButton, Header, LinkAction } from './style';

export default ({ history, location }) => {
  const [loadingDetach, setLoadingDetach] = React.useState(null);

  const [userData, { loading }, refreshViewers] = useService(
    'get',
    `/viewer`,
    {
      page: getQuery(location.search)?.page || 1,
      limit: 200,
    },
    true,
    [location.search]
  );

  const handleOnPaginate = page => {
    history.push({ search: `page=${page}` });
  };

  const [, {}, fetchDetachViewer] = useService('patch', `viewer/setdetach`, {}, false);

  const handleOnDetach = async id => {
    setLoadingDetach(id);
    const result = await fetchDetachViewer({ id: id });

    if (result?.success?.status === 'OK') {
      refreshViewers();
      setLoadingDetach(null);
    }

    if (result?.error) {
      console.log('euu', 'deu ruimm');
    }
  };

  const BASE_URL = '/admin/viewer';

  const configList = [
    {
      name: 'Usuário',
      dataPath: 'name',
      size: {
        md: 3,
      },
    },
    {
      name: 'E-mail',
      dataPath: 'email',
      size: {
        md: 6,
      },
    },
    {
      name: 'Açao',
      dataPath: data => (
        <>
          <LinkAction
            type="link"
            color="dark"
            onClick={() => history.push(BASE_URL + '/' + data._id)}>
            Editar
          </LinkAction>

          <LinkAction type="link" color="dark" onClick={() => handleOnDetach(data._id)}>
            {loadingDetach === data._id && '...'}
            {!data.isDetach ? 'Destacar' : '**Detaque!'}
          </LinkAction>
        </>
      ),
      size: {
        md: 3,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Usuário Público
        </Text>

        <ContentButton>
          <Search
            routeSearch={'/viewer/find-by-term'}
            typeTerm={'name'}
            routeNavigation={BASE_URL}
            placeholder={'Pesquisar por nome...'}
            responseTerm={'viewer'}
          />

          <Space ml={2} />

          <Search
            routeSearch={'/viewer/find-by-term'}
            typeTerm={'email'}
            routeNavigation={BASE_URL}
            placeholder={'Pesquisar por email...'}
            responseTerm={'viewer'}
          />

          <Button
            onClick={() => {
              history.push(`${BASE_URL}/complete-registration`);
            }}>
            Cadastro Completo
          </Button>

          <Button
            onClick={() => {
              history.push(`${BASE_URL}/new`);
            }}>
            Novo
          </Button>
        </ContentButton>
      </Header>

      <Loader show={loading}>
        <Content>
          <Pagination
            total={userData?.success?.viewer?.total?.total}
            page={userData?.success?.viewer?.page}
            limit={userData?.success?.viewer?.limit}
            onChange={handleOnPaginate}
          />

          <Space mt={2} />

          <TableList config={configList} data={userData?.success?.viewer?.data || []} />

          <Space mt={2} />

          <Pagination
            total={userData?.success?.viewer?.total?.total}
            page={userData?.success?.viewer?.page}
            limit={userData?.success?.viewer?.limit}
            onChange={handleOnPaginate}
          />
        </Content>
      </Loader>

      <Switch>
        <Route path={`${BASE_URL}/new`}>
          <Form
            onClose={() => {
              history.push(BASE_URL);
              refreshViewers();
            }}
          />
        </Route>

        <Route path={`${BASE_URL}/complete-registration`}>
          <CompleteForm
            onClose={() => {
              history.push(BASE_URL);
              refreshViewers();
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};
