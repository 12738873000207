import styled, { css } from 'styled-components';
import { TextInput, FormGrid as JDFormGrid } from '@ederzadravec/jade-ui';
import { GrTrophy } from 'react-icons/gr';

import { Text } from 'components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 14px;
  padding: 24px;
  height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DivScroll = styled.div`
  ${({ isScrolling }) =>
    isScrolling &&
    css`
      position: sticky;
      top: 16px;
    `};
`;

export const Messages = styled.div`
  height: 100%;
  overflow: auto;

  @media (max-width: 1366px) {
    height: auto;
  }
`;

export const Message = styled.div`
  margin: 8px 5px 20px 0px;
  position: relative;
`;

export const HeaderMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 10px;
  border-radius: 8px;
`;

export const ContentInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${({ theme, isAdmin }) =>
    isAdmin ? theme.palette.secondary.light : theme.palette.white.light};
  padding: 8px;
  border-radius: 8px;
`;

export const TextMessage = styled(Text)`
  margin-bottom: 8px;
  overflow-wrap: anywhere;

  font-weight: ${({ isAdmin }) => (isAdmin ? 900 : 'normal')};
  font-size: ${({ isAdmin }) => isAdmin && '16px'};
`;

export const CustomTextArea = styled(TextInput)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 15px;
  padding: 24px 8px;
  margin: 32px auto 0;
`;

export const FormGrid = styled(JDFormGrid)`
  max-height: 85px;
  position: relative;
`;

export const Button = styled.div`
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.grey.light};
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 4px;
`;

export const IconTrophy = styled(GrTrophy)`
  position: absolute;
  bottom: 5px;
  left: 36px;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;

  background: ${({ theme }) => theme.palette.tertiary.main};
`;
