import React, { useContext } from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, Button, TextInput, Space } from '@ederzadravec/jade-ui';

import { Room } from 'contexts';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div``;

const validations = {
  question: [[value => !value, 'Deve ser preenchido']],
};

export const Form = ({ onClose }) => {
  const { question, sendQuestionTopic } = React.useContext(Room.Context);
  const [loading, setLoading] = React.useState(false);
  const [form, onChange] = hooks.useForm({ validations });

  const cxtLang = useContext(ContextLanguage);

  React.useEffect(() => {
    setLoading(false);
  }, [question]);

  const handleOnSubmit = async () => {
    setLoading(true);

    const data = {
      questionTopic: question.questionTopic._id,
      content: form.getValue('question'),
    };

    sendQuestionTopic(data);

    form.clear();
  };

  const formConfig = [
    [
      {
        schema: 'question',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          placeholder: `${
            (cxtLang.lang === 'pt' && 'Deixe a sua resposta...') ||
            (cxtLang.lang === 'en' && 'Leave your answer...') ||
            (cxtLang.lang === 'es' && 'Deja tu respuesta...')
          }`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  let titleContainer = '';
  titleContainer = cxtLang.lang === 'pt' && 'Fazer Pergunta';
  titleContainer = cxtLang.lang === 'en' && 'Ask Questions';
  titleContainer = cxtLang.lang === 'es' && 'Hacer Preguntas';

  return (
    <Container size={{ md: 6 }} title={titleContainer} onClose={onClose}>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space top={1}>
          <Button loading={loading}>
            {cxtLang.lang === 'pt' && 'ENVIAR'}
            {cxtLang.lang === 'en' && 'SEND'}
            {cxtLang.lang === 'es' && 'ENVIAR'}
          </Button>
        </Space>
      </FormGrid>
    </Container>
  );
};
