import styled from 'styled-components';

import { Text } from 'components';
import BGLogin from 'assets/images/bg_login.svg';

export const Container = styled.div`
  width: 100%;
  background-image: url(${BGLogin});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    background-position: center;
    flex-direction: column;
  }
`;

export const ContentForm = styled.div`
  width: 350px;
  height: auto;
  margin: auto;
  background: #fff;
  border-radius: 16px;
  padding: 8px 24px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    padding: 16px;
    width: 90%;
    margin-top: 40px;
  }
`;

export const ImageLogo = styled.img`
  display: block;
  max-width: 70%;
  margin: auto;
  padding: 16px;
`;

export const Image = styled.img`
  width: 70%;
  height: auto;
  display: block;
  margin: 16px 24px 16px auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: auto;
  }
`;

export const Footer = styled(Text)`
  color: #bbbbbb;
  bottom: 10px;
  display: none;
`;
