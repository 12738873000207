import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormGrid, hooks, TextInput, Button } from '@ederzadravec/jade-ui';

import { Space, Text } from 'components';
import { useService } from 'service';
import { ContextLanguage } from 'contexts';
import { textsFormRegisterConfirm } from './Texts';

import { Container, ContainerButton, TitleForm } from './style';

const LabelNotice = styled(Text)`
  margin-top: 24px;
  background: #eeeeee;
  border-radius: 8px;
  padding: 16px;
`;

const validations = {};

export const FormRegisterConfirm = ({ setAction }) => {
  const [notice, setNotice] = React.useState(false);
  const history = useHistory();
  const [, { loading }, validViewer] = useService('post', `/app/viewer/register`, null, false);

  const [form, onChange] = hooks.useForm({ validations });

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(textsFormRegisterConfirm);
  }, []);

  const handleOnSubmit = async () => {
    const data = {
      email: form.getValue('email'),
    };

    const result = await validViewer(data);

    if (result?.success?.status === 'OK') {
      setNotice('accept');
    }

    if (result?.error) {
      setNotice('fail');
    }
  };

  const formConfig = [
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => (
          <TitleForm>
            <Text type="h" size="22" color="primary" bold="500">
              {cxtLang.texts[cxtLang.lang].textvalidation}
            </Text>
            <Space mt={1} />
            <hr />
            <Space mt={1} />
          </TitleForm>
        ),
      },
    ],

    [
      {
        schema: 'email',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelFieldEmail}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h" center>
        {cxtLang.texts[cxtLang.lang].title}
      </Text>

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={2} />

        <ContainerButton>
          <Button center loading={loading}>
            {cxtLang.texts[cxtLang.lang].labelButton}
          </Button>
        </ContainerButton>

        <Space mt={2} />

        <Text type="p" center>
          <Text color="primary" type="link" size={14} bold onClick={() => setAction('login')}>
            {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text>
        </Text>
      </FormGrid>

      {notice === 'fail' && (
        <LabelNotice color="alert" center bold type="p">
          {cxtLang.texts[cxtLang.lang].textInvalidEmail} <Space mt={2} />
          {/* <Text type="link" size={14} color="dark" onClick={() => history.push('/')}>
          {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text> */}
        </LabelNotice>
      )}

      {notice === 'accept' && (
        <LabelNotice color="dark" center bold type="p">
          {cxtLang.texts[cxtLang.lang].successMessage}
          <br />
          {/* <Text type="link" size={14} color="dark" bold onClick={() => history.push('/')}>
          {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text> */}
        </LabelNotice>
      )}
    </Container>
  );
};
