import { isValid, fromUnixTime, getUnixTime, parse, format, toDate } from 'date-fns';

import * as locales from 'date-fns/locale';

const FORMATDEFAULT = 'dd/MM/yyyy';

export const isNotValidDate = (date, FORMAT = FORMATDEFAULT) => {
  if (!date) return false;

  const newDate = parse(date, FORMAT, new Date());

  return !isValid(newDate);
};

export const isNotValidTimestamp = date => {
  if (!date) return false;

  const newDate = toDate(date);

  return !isValid(newDate);
};

export const dbToDate = (date, FORMAT = FORMATDEFAULT) => {
  if (!date) return null;

  const newDate = fromUnixTime(date);
  return format(newDate, FORMAT, { locale: locales[navigator.language.replace('-', '')] });
};

export const dateToDb = (date, FORMAT = FORMATDEFAULT) => {
  if (!date) return null;

  const newDate = parse(date, FORMAT, new Date());
  return getUnixTime(newDate);
};

export const newDate = (FORMAT = FORMATDEFAULT) => {
  return format(new Date(), FORMAT);
};

export const newDbDate = () => {
  const newDate = new Date();
  return getUnixTime(newDate);
};
