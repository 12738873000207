import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';
import { Button, Grid } from '@ederzadravec/jade-ui';

import { Text, Box, Space, Toastr } from 'components';
import { FormUser } from './FormUser';
import { FormPassword } from './FormPassword';
import { Store } from 'contexts';
import { useService } from 'service';
import { fileToBase64 } from 'utils/file';

import { ContextLanguage } from 'contexts';
import { useNotificationContext } from 'contexts';

import { homeTexts } from '../../../components/Layout/Texts/HomeTexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentPicture = styled.div`
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-top: 36px;
    padding-top: 20px;
  }
`;

const Picture = styled.img`
  background-color: #777;
  width: 50%;
  border-radius: 14px;
  margin: 0 auto 24px;

  @media (max-width: 768px) {
    margin: 0 auto 24px;
  }
`;

const IconEdit = styled(FaPen)`
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  display: inline-block;
`;

const thisTexts = {
  es: {
    settingsTitleForm: 'PREFERENCIAS',

    settingsTextTitleImage: 'Foto de perfil',
    settingsButtonAddImage: 'Carga de fotos',
    settingsTextImage:
      'Elija una foto de sus archivos personales. Será imprescindible validar tus datos si participas en una promoción o sorteo.',
  },
  en: {
    settingsTitleForm: 'PREFERENCES',

    settingsTextTitleImage: 'Profile Photo',
    settingsButtonAddImage: 'Photo upload',
    settingsTextImage:
      'Choose a photo from your personal files. It will be essential to validate your data if you participate in a promotion or a sweepstakes.',
  },
  pt: {
    settingsTitleForm: 'PREFERÊNCIAS',

    settingsTextTitleImage: 'Foto Perfil',
    settingsButtonAddImage: 'Upload de foto',
    settingsTextImage:
      'Escolha uma foto dos seus arquivos pessoais. Ela será essencial para validar seus dados caso você participe de uma promoção ou de um sorteio.',
  },
};

export default ({ history }) => {
  const { setStore, user } = React.useContext(Store.Context);
  const inputRef = React.useRef();

  const cxtLang = useContext(ContextLanguage);
  const { handleReq } = useNotificationContext();

  React.useEffect(() => {
    handleReq();
  }, []);

  const [, { loading }, updatePhoto] = useService('patch', `viewer/${user.id}/photo`, {}, false);

  const handleOnChange = async () => {
    const photo = inputRef.current.files;

    const photoBase64 = photo && photo.length ? await fileToBase64(photo[0]) : null;

    const data = {
      photo: photoBase64,
    };

    const result = await updatePhoto(data);

    if (result?.success?.status === 'OK') {
      Toastr.open({
        title: 'Foto atualizada, recarregue a página.',
        labelButtom: 'Ok',
      });

      setStore({
        user: {
          ...user,
          photo: result?.success?.user.photo + `?v=${new Date() / 1000}`,
        },
      });
    }

    if (result?.error) {
      console.log('euu', 'foiiiii não');
    }
  };

  return (
    <Container>
      <Box>
        <Text color="secondary" type="h" bold size="24">
          {thisTexts[cxtLang.lang].settingsTitleForm}
        </Text>

        <Space mt={4} />

        <Grid container>
          <Grid size={{ xs: 12, md: 9 }}>
            <ContentForm>
              <FormUser idUser={user?.id} />

              <Space mt={4} />

              <FormPassword idUser={user?.id} />
            </ContentForm>
          </Grid>

          <Grid size={{ xs: 12, md: 3 }}>
            <ContentPicture>
              <Text type="h" size="22" color="black" bold="500">
                {thisTexts[cxtLang.lang].settingsTextTitleImage}
              </Text>

              <input hidden ref={inputRef} type="file" onChange={handleOnChange} />

              <Space mt={2} />

              <hr />

              <Space mt={3} />

              <Picture src={user?.photo} />

              <Button loading={loading} color="black" onClick={() => inputRef.current.click()}>
                {thisTexts[cxtLang.lang].settingsButtonAddImage}
              </Button>

              <Space mt={3} />

              <Text type="p" size="14" color="black">
                {thisTexts[cxtLang.lang].settingsTextImage}
              </Text>
            </ContentPicture>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
