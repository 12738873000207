import React, { useState } from 'react';
import styled from 'styled-components';
import { hooks, TextInput } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Button } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ContentButton = styled.div`
  width: 250px;
  display: inline-block;
  margin-right: 12px;
`;

const validations = {};

export const FormPassword = ({ viewerData }) => {
  const idViewer = viewerData?.success?.viewer?._id;
  const [pass, setPass] = useState('');

  const [form, onChange] = hooks.useForm({ validations });

  const [, { loading: loadingPass }, newPass] = useService(
    'patch',
    `viewer/${idViewer}/newpass`,
    {},
    false
  );

  const HandleNewPassword = async () => {
    const data = {
      admin: true,
      password: pass,
    };

    const result = await newPass(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Uma nova senha foi enviada para o email de cadastro do Usuário',
        confirmButtonText: 'Ok',
      });
    }

    if (result?.error) {
      return form.setError(result?.error);
      console.log('euu', 'deu Ruim');
    }
  };

  return (
    <Container>
      <TextInput
        value={''}
        label="Nova senha"
        onChange={(e, value) => {
          setPass(value);
        }}
      />

      <Button type="button" loading={loadingPass} onClick={() => HandleNewPassword()}>
        Enviar Nova Senha
      </Button>
    </Container>
  );
};
