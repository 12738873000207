import React from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import LogoBrand from 'assets/images/brand.svg';
import BGPage from 'assets/images/bg_login.svg';

import { FormLogin } from './FormLogin';
import { FormRecover } from './FormRecover';

const Container = styled.div`
  width: 100%;
  background-image: url(${BGPage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
`;

const Content = styled.div`
  width: 420px;
  background: #fff;
  border-radius: 14px;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    width: 90%;
    padding: 16px;
  }
`;

const ImageLogo = styled.img`
  display: block;
  max-width: 100%;
  margin: 16px auto 16px;
`;

const Footer = styled(Text)`
  width: 600px;
  color: #bbbbbb;
  position: absolute;
  bottom: 10px;
`;

export default () => {
  const [isRecover, setIsRecover] = React.useState(false);

  return (
    <Container>
      <Content>
        <ImageLogo src={LogoBrand} alt="Logo" />

        {isRecover ? (
          <FormRecover setIsRecover={setIsRecover} />
        ) : (
          <FormLogin setIsRecover={setIsRecover} />
        )}
      </Content>

      <Footer center bold="500">
        Desenvolvido por BTO+ Comunicação e Deploy. Future Agency
      </Footer>
    </Container>
  );
};
