import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  flex: 1;
`;

export const ContainerVideo = styled.div`
  position: relative;
  border: 0;
  overflow: hidden;
  border-radius: 14px;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-color: unset;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
`;
