import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';

import { Text, ButtonDelete } from 'components';
import { Notification } from './notification/Notification';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

export const SetStatus = ({ refresh, data, route }) => {
  const [, { loading }, deleteitem] = useService('patch', route, {}, false);

  const handleOnDelete = async () => {
    await deleteitem();

    refresh();
  };

  return (
    <LinkAction type="link" bold onClick={() => handleOnDelete()}>
      {loading ? '...' : data.isAvailable ? 'Visivel' : 'Não Visivel'}
    </LinkAction>
  );
};

export default ({ history }) => {
  const [notificationsData, { loading }, refreshList] = useService(
    'get',
    '/notification?limit=1000'
  );

  const BASE_URL = '/admin/notification';

  const configList = [
    {
      name: 'Nome',
      dataPath: 'name',
      size: {
        md: 9,
      },
    },
    {
      name: 'Ações',
      dataPath: data => {
        return (
          <>
            <LinkAction type="link" bold onClick={() => history.push(`${BASE_URL}/${data._id}`)}>
              Editar
            </LinkAction>

            <ButtonDelete
              refresh={refreshList}
              name={data.name}
              route={`/notification/${data._id}`}
            />

            <SetStatus
              data={data}
              refresh={refreshList}
              route={`/notification/${data._id}/set-available`}
            />
          </>
        );
      },
      size: {
        md: 3,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Notificações
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Novo
        </Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={notificationsData?.success?.notifications?.data || []}
          />
        </Content>
      </Loader>

      <Switch>
        <Route
          path={[`${BASE_URL}/:id`, `${BASE_URL}/new`]}
          component={props => <Notification {...props} refreshList={refreshList} />}
          exatc
        />
      </Switch>
    </Container>
  );
};
