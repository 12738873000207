import styled from 'styled-components';
import { Text } from '../Text';

import { useService } from 'service';

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

export const ButtonDelete = ({ refresh, name, route }) => {
  const [, { loading }, deleteitem] = useService('delete', route, {}, false);

  const handleOnDelete = async () => {
    const confirmDelele = confirm(`Deseja mesmo deletar ${name}`);
    confirmDelele && (await deleteitem());

    refresh();
  };

  return (
    <LinkAction type="link" bold onClick={() => handleOnDelete()}>
      {loading ? '...' : 'Deletar'}
    </LinkAction>
  );
};
