import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Loader } from '@ederzadravec/jade-ui';

import { Modal } from 'components';
import { useService } from 'service';

import { Form } from './Form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

export const Notification = ({ history, refreshList }) => {
  let { id } = useParams();

  const [notificationData, { loading }] = useService('get', `/notification/${id}`);

  const handleOnClose = refresh => {
    if (refresh) {
      refreshList();
    }

    history.push('/admin/notification');
  };

  return (
    <Modal onClose={() => handleOnClose(false)}>
      <Container>
        <Loader show={loading}>
          <Form notificationData={notificationData} onClose={handleOnClose} />
        </Loader>
      </Container>
    </Modal>
  );
};
