import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Layout, LayoutAdmin, PrivateRoute } from 'components';
import * as Screens from './pages';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Screens.User.Login} exact />
        <Route path="/register" component={Screens.User.RegisterConfirm} exact />
        <Route path="/register/:register" component={Screens.User.RegisterFull} exact />
        <Route path="/logout" component={Screens.Logout} exact />

        <Route path="/admin/login" component={Screens.Admin.Login} exact />

        <Route path="/app">
          <PrivateRoute type="VIEWER">
            <Layout>
              <Route path="/app/award" component={Screens.User.Award} exact />
              <Route path="/app/best-sellers" component={Screens.User.BestSellers} exact />
              <Route path="/app/schedule" component={Screens.User.Schedule} exact />
              <Route path="/app/schedule2" component={Screens.User.ScheduleTwo} exact />
              <Route path="/app/settings" component={Screens.User.Settings} exact />
              <Route path="/app/posters" component={Screens.User.Posters} exact />
              <Route path="/app/sponsors" component={Screens.User.Sponsors} exact />
              <Route path="/app" component={Screens.User.Home} exact />
              <Route path="/app/result/room/:room" component={Screens.User.ResultSearch} exact />
              <Route path="/app/room/:room" component={Screens.User.SingleRoom} exact />
            </Layout>
          </PrivateRoute>
        </Route>

        <Route path="/admin">
          <PrivateRoute type="ADMIN">
            <LayoutAdmin>
              <Route path="/admin/award" component={Screens.Admin.Awards} />
              <Route path="/admin/banner" component={Screens.Admin.Banners} />
              <Route path="/admin/" component={Screens.Admin.Dashboard} exact />
              <Route path="/admin/header" component={Screens.Admin.Header} />
              <Route path="/admin/in-live" component={Screens.Admin.InLive} />
              <Route path="/admin/instagram" component={Screens.Admin.Instagram} />
              <Route path="/admin/notification" component={Screens.Admin.Notifications} />
              <Route path="/admin/room" component={Screens.Admin.Rooms} />
              <Route path="/admin/settings" component={Screens.Admin.Settings} />
              <Route path="/admin/speaker" component={Screens.Admin.Speakers} />
              <Route path="/admin/user" component={Screens.Admin.Users} />
              <Route path="/admin/viewer" component={Screens.Admin.Viewers} />
              <Route path="/admin/wall" component={Screens.Admin.Walls} />
            </LayoutAdmin>
          </PrivateRoute>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
