import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';
import { Checkbox, InputText, Space, Select } from 'components';

import { useService } from '../../../../../service';
import { textForm } from './Texts';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormCheckYes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormCheckNot = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormCheckSponsors = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const dataSelect = [
  {
    label: 'BMS',
    value: 'BMS',
  },
  {
    label: 'JANSSEN',
    value: 'JANSSEN',
  },
  {
    label: 'LIBBS',
    value: 'LIBBS',
  },
  {
    label: 'MSD',
    value: 'MSD',
  },
  {
    label: 'AMGEN',
    value: 'AMGEN',
  },
  {
    label: 'ROCHE',
    value: 'ROCHE',
  },
  {
    label: 'ZODIAC',
    value: 'ZODIAC',
  },
  {
    label: 'ASTELLAS',
    value: 'ASTELLAS',
  },
  {
    label: 'DAIICHI + ASTRAZENECA',
    value: 'DAIICHI + ASTRAZENECA',
  },
  {
    label: 'PFIZER',
    value: 'PFIZER',
  },
  {
    label: 'SANDOZ',
    value: 'SANDOZ',
  },
  {
    label: 'TAKEDA',
    value: 'TAKEDA',
  },
  {
    label: 'ABBVIE',
    value: 'ABBVIE',
  },
  {
    label: 'GE',
    value: 'GE',
  },
  {
    label: 'GSK',
    value: 'GSK',
  },
  {
    label: 'MERCK',
    value: 'MERCK',
  },
  {
    label: 'NOVARTIS',
    value: 'NOVARTIS',
  },
  {
    label: 'SANOFI',
    value: 'SANOFI',
  },
  {
    label: 'VARIAN',
    value: 'VARIAN',
  },
  {
    label: 'QIAGEN',
    value: 'QIAGEN',
  },
];

export const FormSponsors = () => {
  const [check, setCheck] = useState('');
  const [checkAgre, setCheckAgre] = useState('');

  const cxtLang = useContext(ContextLanguage);

  const history = useHistory();

  const [data, setData] = useState({
    name: '',
    profession: '',
    board: '',
    numberBoard: '',
    email: '',
    sponsors: '',
    form: '',
  });

  useMemo(() => {
    setData({ ...data, form: check });
  }, [check]);

  const [error, setError] = useState({
    name: '',
    profession: '',
  });

  const [, { loading }, postTermsAccepted] = useService(
    'post',
    'https://api-hello-new-features-2-ie779.ondigitalocean.app/form',
    {},
    false
  );

  const handleOnSubmit = async () => {
    if (data.name === '') {
      setError({ ...error, name: textForm[cxtLang.lang].field });

      return;
    } else {
      setError({ ...error, name: '' });
    }
    if (data.profession === '') {
      setError({ ...error, profession: textForm[cxtLang.lang].field });

      return;
    } else {
      setError({ ...error, profession: '' });
    }

    const result = await postTermsAccepted(data);

    if (result.status === 'OK') {
      setCheck('');
      setData({});
      setError({});

      if (checkAgre === 'AgreeCheckYes' || checkAgre === 'AgreeCheckSponsers') {
        window.open('https://www.360up.com.br/tourvirtual/thepublichouse/accamargo', '_blank');
      } else if (checkAgre === 'AgreeCheckNot') {
        window.open('https://www.360up.com.br/tourvirtual/thepublichouse/accamargo.np', '_blank');
      }
      history.push('/app');
    }

    if (result.status === 'ERROR') {
      console.log('eee', 'deu ruim');
    }
  };

  return (
    <Container>
      <Checkbox
        label={textForm[cxtLang.lang].checkboxYes}
        checked={check === 'FormCheckYes'}
        onClick={() => {
          setCheck('FormCheckYes');
          setCheckAgre('');
        }}
      />

      <Checkbox
        label={textForm[cxtLang.lang].checkboxNo}
        checked={check === 'FormCheckNot'}
        onClick={() => {
          setCheck('FormCheckNot');
          setCheckAgre('');
        }}
      />

      <Checkbox
        label={textForm[cxtLang.lang].checkboxSponsors}
        checked={check === 'FormCheckSponsors'}
        onClick={() => {
          setCheck('FormCheckSponsors');
          setCheckAgre('');
        }}
      />

      <Space mt={2} />

      {check === 'FormCheckYes' && (
        <FormCheckYes>
          <InputText
            label={textForm[cxtLang.lang].inputName}
            placeholder={textForm[cxtLang.lang].inputName}
            value={data.name}
            error={error.name}
            onChange={e => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label={textForm[cxtLang.lang].inputProfession}
            placeholder={textForm[cxtLang.lang].inputProfession}
            value={data.profession}
            error={error.profession}
            onChange={e => {
              setData({
                ...data,
                profession: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label={textForm[cxtLang.lang].inputBoard}
            placeholder={textForm[cxtLang.lang].inputBoard}
            value={data.board}
            onChange={e => {
              setData({
                ...data,
                board: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label={textForm[cxtLang.lang].inputBoardNumber}
            placeholder={textForm[cxtLang.lang].inputBoardNumber}
            value={data.numberBoard}
            onChange={e => {
              setData({
                ...data,
                numberBoard: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label="E-mail"
            placeholder="E-mail"
            value={data.email}
            error={error.email}
            onChange={e => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />

          <Checkbox
            label={textForm[cxtLang.lang].agreeCheck}
            checked={checkAgre === 'AgreeCheckYes'}
            onClick={() => {
              setCheckAgre('AgreeCheckYes');
            }}
          />

          <Space mt={2} />

          {checkAgre !== 'AgreeCheckYes' ? (
            <Button width="30" disabled color="primary" onClick={() => handleOnSubmit()}>
              {loading ? `${textForm[cxtLang.lang].sending}` : `${textForm[cxtLang.lang].button}`}
            </Button>
          ) : (
            <Button
              width="30"
              color="primary"
              onClick={() => {
                handleOnSubmit();
              }}>
              {loading ? `${textForm[cxtLang.lang].sending}` : `${textForm[cxtLang.lang].button}`}
            </Button>
          )}

          <Space mt={2} />
        </FormCheckYes>
      )}

      {check === 'FormCheckNot' && (
        <FormCheckNot>
          <InputText
            label={textForm[cxtLang.lang].inputName}
            placeholder={textForm[cxtLang.lang].inputName}
            value={data.name}
            error={error.name}
            onChange={e => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label={textForm[cxtLang.lang].inputProfession}
            placeholder={textForm[cxtLang.lang].inputProfession}
            value={data.profession}
            error={error.profession}
            onChange={e => {
              setData({
                ...data,
                profession: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label="E-mail"
            placeholder="E-mail"
            value={data.email}
            error={error.email}
            onChange={e => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />

          <Space mt={1} />

          <Checkbox
            label={textForm[cxtLang.lang].agreeCheck}
            checked={checkAgre === 'AgreeCheckNot'}
            onClick={() => {
              setCheckAgre('AgreeCheckNot');
            }}
          />

          <Space mt={2} />

          <Button
            width="30"
            disabled={checkAgre !== 'AgreeCheckNot'}
            color="primary"
            onClick={() => handleOnSubmit()}>
            {loading ? `${textForm[cxtLang.lang].sending}` : `${textForm[cxtLang.lang].button}`}
          </Button>

          <Space mt={2} />
        </FormCheckNot>
      )}

      {check === 'FormCheckSponsors' && (
        <FormCheckSponsors>
          <InputText
            label={textForm[cxtLang.lang].inputName}
            placeholder={textForm[cxtLang.lang].inputName}
            value={data.name}
            error={error.name}
            onChange={e => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label={textForm[cxtLang.lang].inputProfession}
            placeholder={textForm[cxtLang.lang].inputProfession}
            value={data.profession}
            error={error.profession}
            onChange={e => {
              setData({
                ...data,
                profession: e.target.value,
              });
            }}
          />

          <Space mt={2} />

          <InputText
            label="E-mail"
            placeholder="E-mail"
            value={data.email}
            error={error.email}
            onChange={e => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />

          <Select
            label="Patrocinadores"
            value={data.sponsors}
            data={dataSelect}
            onChange={e => {
              setData({
                ...data,
                sponsors: e.value,
              });
            }}
          />

          <Space mt={4} />

          <Checkbox
            label={textForm[cxtLang.lang].agreeCheck}
            checked={checkAgre === 'AgreeCheckSponsers'}
            onClick={() => {
              setCheckAgre('AgreeCheckSponsers');
            }}
          />

          <Space mt={2} />

          {checkAgre !== 'AgreeCheckSponsers' ? (
            <Button width="30" disabled color="primary" onClick={() => handleOnSubmit()}>
              {loading ? `${textForm[cxtLang.lang].sending}` : `${textForm[cxtLang.lang].button}`}
            </Button>
          ) : (
            <Button width="30" color="primary" onClick={() => handleOnSubmit()}>
              {loading ? `${textForm[cxtLang.lang].sending}` : `${textForm[cxtLang.lang].button}`}
            </Button>
          )}

          <Space mt={2} />
        </FormCheckSponsors>
      )}
    </Container>
  );
};
