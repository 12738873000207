import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.main};
  }

  & > div:last-child {
    border-bottom: 1px solid transparent;
  }
`;
