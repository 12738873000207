import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';

import { Text, Space } from 'components';
import { useService } from 'service';

const Container = styled.div`
  margin: 16px 0 32px;
  width: 100%;
`;

const ContainerItem = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const ContentQuestion = styled.div``;

const ContentLikes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Like = styled(Text)`
  cursor: pointer;
`;

const IconLike = styled(AiOutlineLike)`
  font-size: 16px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 8px;
  align-self: center;
`;

const UnLike = styled(Text)`
  cursor: pointer;
`;

const IconUnLike = styled(AiOutlineDislike)`
  font-size: 16px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 8px;
  align-self: center;
`;

const Item = ({ data }) => {
  return (
    <ContainerItem>
      <ContentQuestion>
        <Text color="primary" size="16" bold="500">
          {`${data.viewer.name}: `}
        </Text>

        <Space top={1} />

        <Text color="dark" size="16">
          {data.question}
        </Text>
      </ContentQuestion>

      <ContentLikes>
        <>
          <Like color="primary" size="16" bold="500">
            {data.like} <IconLike as={data.userLiked ? AiFillLike : AiOutlineLike} />
          </Like>

          <UnLike color="primary" size="16" bold="500">
            {data.unlike} <IconUnLike as={data.userUnliked ? AiFillDislike : AiOutlineDislike} />
          </UnLike>
        </>
      </ContentLikes>
    </ContainerItem>
  );
};

export const ListQuestions = ({ idTopic }) => {
  const { room: idRoom } = useParams();

  const [dataQuestions, {}, fetchQuestions] = useService(
    'get',
    `/room/${idRoom}/question-topic/${idTopic}/questions`
  );

  const data = dataQuestions?.success?.questions || [];

  const sortedData = data
    .map(question => {
      const like = question?.like?.length || 0;

      const unlike = question?.unlike?.length || 0;

      return {
        ...question,
        like,
        unlike,
      };
    })
    .sort((x, y) => y.like - x.like);

  return (
    <Container>
      {sortedData.map(item => (
        <Item key={item._id} data={item} />
      ))}
    </Container>
  );
};
