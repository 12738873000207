import React from 'react';
import { useHistory } from 'react-router-dom';

import { Store } from 'contexts';

export const PrivateRoute = ({ children, type }) => {
  const history = useHistory();
  const { session, type: sessionType, user } = React.useContext(Store.Context);

  React.useEffect(() => {
    if (!session) return history.push('/');

    if (sessionType !== type) return history.push('/');

    return;
  }, []);

  return children;
};
