import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '@ederzadravec/jade-ui';
import { FaWindowClose } from 'react-icons/fa';

import * as R from 'ramda';

import { useService } from 'service';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  padding: 8px 8px;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  border: 1px solid
    ${({ theme, error }) => (!error ? theme.palette.primary.main : theme.palette.alert.main)};

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Result = styled.div`
  display: ${({ visible }) => (visible ? `block` : `none`)};

  position: absolute;
  top: 50px;
  background-color: #fff;
  width: 100%;
  padding: 4px 16px;
  max-height: 200px;
  overflow: auto;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 4px;
`;

const ItemResult = styled.h3`
  margin-bottom: 4px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background: #dedede;
  }
`;

export const Search = ({
  typeTerm,
  routeSearch,
  routeNavigation,
  placeholder,
  responseTerm,
  ...props
}) => {
  const [input, setInput] = React.useState('');
  const [showResult, setShowResult] = React.useState(false);
  const [sendSearch, setSendSearch] = React.useState('');

  const [request, {}] = useService('get', `${routeSearch}`, { [typeTerm]: input }, true, [
    sendSearch,
  ]);

  const data = request?.success[responseTerm]?.data || [];

  const history = useHistory();

  const handleOnChange = e => {
    e.preventDefault();

    //    e.target.value ? setShowResult(true) : setShowResult(false);

    setInput(e.target.value);
  };

  const handleOnPush = id => {
    history.push(`${routeNavigation}/${id}`);

    setShowResult(false);

    setInput('');
  };

  return (
    <Container {...props}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Input type="text" placeholder={placeholder} onChange={handleOnChange} value={input} />

        <Button
          style={{ minWidth: '80px' }}
          color="primary"
          onClick={() => {
            setShowResult(true);
            input === '' ? {} : setSendSearch(new Date());
          }}>
          Buscar
        </Button>
      </div>

      {showResult && (
        <FaWindowClose
          style={{ position: 'absolute', top: '20px', fontSize: '20px', left: '-25px' }}
          onClick={() => setShowResult(false)}
        />
      )}
      <Result visible={showResult}>
        {data.map((item, key) => (
          <ItemResult
            key={key}
            type="h"
            size="18"
            color="dark"
            onClick={() => {
              handleOnPush(item._id);
            }}>
            {item[typeTerm]}
          </ItemResult>
        ))}
      </Result>
    </Container>
  );
};

Text.defaultProps = {
  route: '',
};
