export const textForm = {
  es: {
    checkboxYes: 'Sí, declaro que soy dentista, farmacéutico, médico o veterinario',
    checkboxNo: 'No',
    checkboxSponsors: 'Soy un patrocinador de eventos',

    inputName: 'Nombre',
    inputProfession: 'Profesión',
    inputBoard: 'Asesoramiento (Iniciales)',
    inputBoardNumber: 'Consejo / Estado No.',

    button: 'Acceso.',

    sending: 'Enviando...',

    field: 'Este campo debe completarse.',
    agreeCheck:
      'Declaro que la información es veraz, siendo consciente de que la falsedad de los datos proporcionados dará lugar a las sanciones aplicables.',
  },
  en: {
    checkboxYes: 'Yes, I declare that I am a dentist, pharmacist, doctor or veterinarian',
    checkboxNo: 'No',
    checkboxSponsors: "I'm an Event Sponsor",

    inputName: 'Name',
    inputProfession: 'Profession',
    inputBoard: 'Board (Initials)',
    inputBoardNumber: 'Board/State No.',

    button: 'Access',

    sending: 'Sending...',

    field: 'This field must be filled.',
    agreeCheck:
      'I declare that the information is true, being aware that the falsity of the data provided will result in the applicable penalties.',
  },
  pt: {
    checkboxYes: 'Sim',
    checkboxNo: 'Não',
    checkboxSponsors: 'Sou Patrocinador do Evento',

    inputName: 'Nome',
    inputProfession: 'Profissão/Cargo',
    inputBoard: 'Conselho (Sigla)',
    inputBoardNumber: 'Nº Conselho/Estado',

    button: 'Acessar',

    sending: 'Enviando... ',

    field: 'Este campo deve ser preenchido',

    agreeCheck:
      ' Declaro que as informações são verdadeiras, estando ciente de que a falsidade dos dados prestados implicará nas penalidades cabíveis.',
  },
};
