import styled from 'styled-components';

export const ButtonStyle = styled.button`
    ${({ alingRight }) => alingRight ?
    `display: block;
    float: right;` : ``};

    background: ${({ theme, color }) =>
    color ? theme.palette[color]?.main : theme.palette.primary.reverse};

    border: 1px solid ${({ theme, color }) =>
    color ? theme.palette[color].main : theme.palette.primary.main};

    color: ${({ theme, color }) =>
    color ? theme.palette[color].reverse : theme.palette.primary.main};

    width: ${({ width }) => width ? `${width}%` : 'auto'};

    font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : 'normal'};

    font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : ''};

    padding: ${({ padding }) => padding ? ` ${padding}px;` : '12px 40px'};

    ${({ margin }) => margin ? `margin: ${margin};` : ''};

    ${({ height }) => height ? `height: ${height}px;` : ''};

    border-radius: 10px;
    cursor: pointer;

    &:focus{
      outline: none;

    }

    ${({ outline, color, theme }) =>
    outline ? `
      background: transparent;
      color: ${color ? theme.palette[color].main : theme.palette.primary.main};}
      border: 1px solid ${color ? theme.palette[color].main : theme.palette.primary.main};}
    ` : ``}

    ${({ disabled, color, theme }) =>
    disabled ? `
        background: ${theme.palette.grey.light};
        color: ${color ? theme.palette.grey.dark : ''};}
        border: 1px solid ${color ? theme.palette.grey.dark : ''};}
        cursor: not-allowed;

        &:hover{
          border: 1px solid ${color ? theme.palette.grey.dark : ''};}
        }
      ` : ``}

    @media (max-width: 768px) {
      width: ${({ width }) => width ? `${100}%` : 'auto'};
    }
`;