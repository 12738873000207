const MIME_TO_EXT = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'application/pdf': 'pdf',
};

const EXT_TO_MIME = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png',
  gif: 'image/gif',
  pdf: 'application/pdf',
};

export const fileToBase64 = file => {
  if (!file) return file;

  if (typeof file === 'string') return file;

  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const baseURL = reader.result;

      resolve({ data: baseURL, ext: MIME_TO_EXT[file.type] });
    };

    reader.readAsDataURL(file);
  });
};

export const validFile = (file, acceptExt = []) => {
  if (!file) return true;

  if (typeof file === 'string') return true;

  const acceptTypes = acceptExt.map(item => EXT_TO_MIME[item]);

  return acceptTypes.includes(file.type);
};
