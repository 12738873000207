import { Switch, Route } from 'react-router-dom';

import List from './List';
import { Form } from './Form';
import Room from './room/Room';

export default () => {
  const BASE_URL = '/admin/room';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`]} component={List} exact />
      <Route path={[`${BASE_URL}/new`, `${BASE_URL}/edit/:room`]} component={Form} />
      <Route path={`${BASE_URL}/details/:room`} component={Room} />
    </Switch>
  );
};
