import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [date, setDate] = useState('');

  const handleReq = () => {
    setDate(new Date());
  };

  return (
    <NotificationContext.Provider value={{ handleReq, date }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const notificationContext = useContext(NotificationContext);

  return notificationContext;
};
