import styled, { css } from 'styled-components';

const defaults = css`
  ${({ center }) => center && 'text-align: center; width: 100%;'};
  ${({ end }) =>
    end &&
    css`
      text-align: end;
      width: 100%;
    `};
  ${({ link }) =>
    link &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `};

  ${({ finality }) =>
    finality === 'title'
      ? css`
          font-size: 18px;
          font-weight: 600;
        `
      : css`
          font-size: 16px;
          font-weight: 400;
        `}

  color: ${({ theme, color, variant }) =>
    color ? (variant ? theme.palette[color][variant] : theme.palette[color].main) : ''};

  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: ${bold};
    `};

  @media (max-width: 786px) {
    ${({ mobile }) =>
      mobile &&
      css`
        ${mobile}
      `}
  }
`;

export const H1 = styled.h1`
  ${defaults}
`;

export const Span = styled.span`
  ${defaults}
`;

export const Link = styled.span`
  ${defaults}

  &:hover {
    ${({ noDecoration }) =>
      !noDecoration &&
      css`
        text-decoration: underline;
      `}
    cursor: pointer;
  }
`;

export const P = styled.p`
  ${defaults}
`;
