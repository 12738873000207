import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Room from './Room';

export default () => {
  const BASE_URL = '/admin/in-live';

  return (
    <Switch>
      <Route path={`${BASE_URL}`} component={List} exact />
      <Route path={`${BASE_URL}/:room`} component={Room} exact />
    </Switch>
  );
};
