import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { Button } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { ThisHeader } from './ThisHeader';
import { FormHeader } from './FormHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
`;

export default ({ history }) => {
  const BASE_URL = '/admin/header';

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Header
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/edit`);
          }}>
          Editar
        </Button>
      </Header>
      <Content />

      <Switch>
        <Route path={[`${BASE_URL}`]} component={ThisHeader} exact />

        <Route path={`${BASE_URL}/edit`} component={FormHeader} />
      </Switch>
    </Container>
  );
};
