import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { hooks, FormGrid, Button, TextInput, Loader, Select, Space } from '@ederzadravec/jade-ui';

import { Modal } from 'components';
import { useService } from 'service';
import { isNotValidDate, dateToDb, dbToDate } from 'utils/date';

const validations = {
  startAt: [
    [value => !value, 'Deve ser preenchido'],
    [value => isNotValidDate(value, 'dd/MM/yyyy HH:mm'), 'Deve ser uma data válida'],
  ],
  endAt: [
    [value => !value, 'Deve ser preenchido'],
    [value => isNotValidDate(value, 'dd/MM/yyyy HH:mm'), 'Deve ser uma data válida'],
  ],
  titlePt: [[value => !value, 'Deve ser preenchido']],
  speaker: [[value => !value?._id, 'Deve ser preenchido']],
};

export const FormTalk = ({ BASE_URL, id_room, onClose }) => {
  let { talk: id_talk } = useParams();

  const [, { loading: loadingTalk }, fetchTalk] = useService(
    'get',
    `/room/${id_room}/talk/${id_talk}`,
    {},
    false
  );

  const [speakers, { loading: loadingSpeakers }] = useService('get', '/speaker?limit=100');
  const [, { loading }, saveTalk] = useService('post', `room/${id_room}/talk`, {}, false);
  const [, { loading: updateLoading }, updateTalk] = useService(
    'patch',
    `room/${id_room}/talk/${id_talk}`,
    {},
    false
  );

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    if (!id_talk) {
      return;
    }

    fetchTalk().then(data => {
      if (data?.error) {
        console.log('euu', 'deu ruim');
      }

      if (data?.success?.status === 'OK') {
        const talk = data?.success?.talk;
        form.setValues({
          ...talk,
          titlePt: talk?.title?.pt,
          titleEn: talk?.title?.en,
          titleEs: talk?.title?.es,
          startAt: dbToDate(talk?.startAt, 'dd/MM/yyyy HH:mm'),
          endAt: dbToDate(talk?.endAt, 'dd/MM/yyyy HH:mm'),
        });
      }
    });
  }, []);

  const handleOnSubmit = async () => {
    const data = {
      titlePt: form.getValue('titlePt'),
      titleEn: form.getValue('titleEn'),
      titleEs: form.getValue('titleEs'),
      startAt: dateToDb(form.getValue('startAt'), 'dd/MM/yyyy HH:mm'),
      endAt: dateToDb(form.getValue('endAt'), 'dd/MM/yyyy HH:mm'),
      speaker: form.getValue('speaker')._id,
    };

    const result = id_talk ? await updateTalk(data) : await saveTalk(data);

    if (result?.success?.status === 'OK') {
      return onClose(true);
    }

    if (result?.error?.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'titlePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'startAt',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Abertura',
          variant: 'timedate',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'endAt',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Fechamento',
          variant: 'timedate',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'speaker',
        size: { md: 12 },
        type: Select,
        props: schema => ({
          label: 'Palestrantes',
          data: speakers?.success?.speakers?.data || [],
          value: form.getValue(schema),
          error: form.getError(schema),
          config: { value: '_id', label: 'name' },
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Modal
      size={{ md: 6 }}
      title="Cadastrar Palestra"
      onClose={() => {
        onClose(false);
      }}>
      <Loader show={loadingSpeakers || loadingTalk}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <Space top={3}>
            <Button loading={loading || updateLoading}>Enviar</Button>
          </Space>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
