import React from 'react';
import styled from 'styled-components';
import { hooks, FormGrid as JDFormGrid, TextInput, Button } from '@ederzadravec/jade-ui';
import { FaStar } from 'react-icons/fa';
import ScrollableFeed from 'react-scrollable-feed';
import { MdSync, MdSyncDisabled } from 'react-icons/md';

import AvatarTotvs from 'assets/images/avatarImage.jpg';
import { useService } from 'service';
import { Room } from 'contexts';
import { Text, Space } from 'components';
import { dbToDate } from 'utils/date';

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 14px;
  padding: 16px 24px 16px;
  position: relative;
`;

const Header = styled(Text)`
  display: flex;
  background: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
  font-size: 16px;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 14px;
`;

const Messages = styled.div`
  height: 300px;
  overflow: auto;

`;

const Message = styled.div`
  margin: 8px 8px 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.light};
`;

const HeaderMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 10px;
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextMessage = styled(Text)`
  margin-bottom: 8px;
  overflow-wrap: anywhere;
`;

const CustonTextArea = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 14px;
  width: 100%;
  margin: 32px auto 0;
  box-sizing: border-box;
`;

const FormGrid = styled(JDFormGrid)`
  max-height: 140px;
`;

const Detach = styled(Text)`
  margin-left: 10px;
`;

const Hide = styled(Text)`
  margin-left: 10px;
`;

const DetachMessage = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  max-height: 100px;
  overflow: auto;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconSync = styled(MdSync)`
  margin: 2px 0 -2px 8px;
  font-size: 16px;
`;

const IconNotSync = styled(MdSyncDisabled)`
  margin: 2px 0 -2px 8px;
  font-size: 16px;
`;

const Icon = styled(FaStar)`
  color: ${({ active }) => (active ? `#333333` : `#dedede`)};
`;

const validations = {
  content: [[value => !value, 'Deve ser preenchido']],
};

export const FormChat = ({ idRoom }) => {
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  const { chat, sendChat } = React.useContext(Room.Context);

  const [, { loading: loadingDetach }, updateDetach] = useService(
    'patch',
    `/chat/${idRoom}/set-detach`,
    {},
    false
  );

  const [, { loading: loadingHide }, updateHide] = useService(
    'patch',
    `/chat/${idRoom}/set-hide`,
    {},
    false
  );

  const listOfChat = Object.values(chat || {}) || [];
  const detach = listOfChat?.find(item => item.detach);

  const [form, onChange] = hooks.useForm({ validations });

  const formConfig = [
    [
      {
        schema: 'content',
        size: { xs: 12, md: 12 },
        type: CustonTextArea,
        props: schema => ({
          label: '',
          debounce: 0,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  const handleOnSubmit = async () => {
    form.clear();

    setLoading(true);

    const data = {
      content: form.getValue('content'),
    };

    await sendChat(data);
  };

  React.useEffect(() => {
    setLoading(false);
  }, [chat]);

  const handleOnDetach = async (_id, isDetach = true) => {
    const result = await updateDetach({
      _id,
      detach: isDetach,
    });

    if (result?.success?.status === 'OK') {
      console.log('euu', 'Destacada');
    }

    if (result?.error) {
      console.log('euu', 'deu Ruim');
    }
  };

  const handleOnHide = async (id, isHide) => {
    const result = await updateHide({
      _id: id,
      hide: isHide,
    });

    if (result?.success?.status === 'OK') {
      console.log('euu', 'Ocultada');
    }

    if (result?.error) {
      console.log('euu', 'deu Ruim');
    }
  };

  return (
    <>
      <Container>
        <Header type="h" size="24" color="primary" bold="500">
          Chat Admin
        </Header>

        <Messages>
          <ScrollableFeed forceScroll={autoScroll}>
            {listOfChat.map((item, key) => (
              <Message key={key}>
                <HeaderMessage>
                  {item.viewer && <Avatar src={item.viewer.photo} />}
                  {item.user && <Avatar src={AvatarTotvs} />}

                  <ContentInfo>
                    <Text size="18"  type="h" color="primary">
                      {item.user && 'TOTVS'}
                      {item.viewer && item.viewer.name}
                      {loadingDetach ? (
                        '...'
                      ) : (
                        <Detach
                          size="14"
                          
                          type="link"
                          color="primary"
                          onClick={() => handleOnDetach(item._id, true)}>
                          <Icon active={item.detach} />
                          Destacar
                        </Detach>
                      )}

                      {loadingHide ? (
                        '...'
                      ) : (
                        <Hide  type="link" color="primary">
                          <Icon active={item.hide} type="link" />

                          <Text
                            size="14"
                            
                            type="link"
                            color="primary"
                            onClick={() => handleOnHide(item._id, !item.hide)}>
                            {item.hide ? 'Mostrar' : 'Esconder'}
                          </Text>
                        </Hide>
                      )}
                    </Text>

                    <Text size="16"  type="p" color="primary">
                      em: {dbToDate(item.createdAt, 'HH:mm')}
                    </Text>
                  </ContentInfo>
                </HeaderMessage>

                <TextMessage type="p" color="dark" size="14">
                  {item.content}
                </TextMessage>
              </Message>
            ))}
          </ScrollableFeed>
        </Messages>

        {detach?.content && (
          <DetachMessage>
            <Text size="16">
              Destaque{' '}
              <Text type="link" onClick={() => handleOnDetach(detach._id, false)} bold="500">
                Limpar
              </Text>
            </Text>
            <Text size="22" color="dark">
              <Text type="h" size="18" bold color="primary">
                {detach.user && detach.user.name + ' ADM'}
                {detach.viewer && detach.viewer.name}
              </Text>
              {detach.content}
            </Text>
          </DetachMessage>
        )}

        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <ContainerButtons>
            <Button center loading={loading} color="primary">
              ENVIAR
            </Button>

            <Button
              variant={autoScroll ? '' : 'outline'}
              type="button"
              onClick={() => setAutoScroll(!autoScroll)}>
              Scroll {autoScroll ? <IconSync /> : <IconNotSync />}
            </Button>
          </ContainerButtons>
        </FormGrid>

        <Space mt={3} />
      </Container>
    </>
  );
};
