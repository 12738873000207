import React from 'react';
import styled from 'styled-components';

import { Tabs } from 'components';

import { Quiz, Poll, Html, Question } from './';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
`;

const TABS = {
  quiz: () => <Quiz />,
  poll: () => <Poll />,
  html: () => <Html />,
  question: () => <Question />,
};

export const Interactions = () => {
  const [active, setActive] = React.useState('quiz');

  const tabConfig = [
    {
      label: 'Quiz',
      onClick: () => setActive('quiz'),
      active: () => active === 'quiz',
    },
    {
      label: 'Votação',
      onClick: () => setActive('poll'),
      active: () => active === 'poll',
    },
    {
      label: 'Html',
      onClick: () => setActive('html'),
      active: () => active === 'html',
    },
    {
      label: 'Question',
      onClick: () => setActive('question'),
      active: () => active === 'question',
    },
  ];

  const Active = React.useMemo(() => TABS[active], [active]);

  return (
    <>
      <Tabs config={tabConfig} />

      <Content>
        <Active />
      </Content>
    </>
  );
};
