import { Switch, Route } from 'react-router-dom';

import List from './List';
import Viewer from './viewer/Viewer';

export default () => {
  const BASE_URL = '/admin/viewer';

  return (
    <Switch>
      <Route
        path={[`${BASE_URL}`, `${BASE_URL}/new`, `${BASE_URL}/complete-registration`]}
        component={List}
        exact
      />
      <Route path={`${BASE_URL}/:id`} component={Viewer} />
    </Switch>
  );
};
