import React from 'react';

import { Space } from '../../Space/Space';
import { Text } from '../../Text';

export const ErrorForm = ({ content }) => {
  return (
    <>
      <Space mt={-1} />
      <Text size={12} style={{ color: 'red' }}>
        {content}
      </Text>
    </>
  );
};
