export const isInArray = (arrayState, item) => {
  return arrayState.includes(item);
};

export const addRemoveItem = (
  arrayState,
  setArrayState,
  item,
) => {
  if (isInArray(arrayState, item)) {
    const thisArray = arrayState.indexOf(item);

    if (thisArray !== -1) {
      arrayState.splice(thisArray, 1);
      setArrayState((arrayState) => [...arrayState]);
      return;
    }
  }
  setArrayState((arrayState) => [...arrayState, item]);
  return;
};

export const isAllSelecteds = (arrayState, arrayApi) => {
  const arrayRef = arrayApi.map((item) => {
    return item.id;
  });

  function arrayEquals(array, arrayApi) {
    return (
      Array.isArray(array) &&
      Array.isArray(arrayApi) &&
      arrayState.length === arrayApi.length &&
      arrayState.every((val, index) => val === arrayApi[index])
    );
  }

  return arrayEquals(arrayState.sort(), arrayRef.sort());
};

export const handleSelectAll = (
  arrayState,
  setArrayState,
  arrayApi,
) => {
  const arrayRef = arrayApi.map((item) => {
    return item?.id;
  });

  function arrayEquals(array, arrayRef) {
    return (
      Array.isArray(array) &&
      Array.isArray(arrayRef) &&
      arrayState.length === arrayRef.length &&
      arrayState.every((val, index) => val === arrayRef[index])
    );
  }

  if (arrayEquals(arrayState.sort(), arrayRef.sort())) {
    setArrayState([]);
    return;
  }
  setArrayState(
    arrayApi?.map((item) => {
      return item.id;
    }),
  );
};
