import styled from 'styled-components';
import { TableList, Loader } from '@ederzadravec/jade-ui';
import { useLocation } from 'react-router-dom';

import { Box, Text, Space } from 'components';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled(Box)`
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const StatisticsByUser = () => {
  const location = useLocation();
  const query = getQuery(location.search);

  const [viewersStatistics, { loading: loadingViewersStatistics }] = useService(
    'get',
    '/dashboard/viewers-statistics',
    { room: query.room },
    true,
    [query.room]
  );

  const configList = [
    {
      name: 'Usuario',
      dataPath: 'viewer.name',
      size: {
        md: 4,
      },
    },

    {
      name: 'Tempo Assistido',
      dataPath: data => `${data.hours || '00'}:${data.minutes || '00'}:${data.seconds || '00'}`,
      size: {
        md: 4,
      },
    },

    {
      name: 'Interações',
      dataPath: data => data.polls + data.quizzes,
      size: {
        md: 4,
      },
    },
  ];

  return (
    <Container>
      <Text type="h" bold color="primary">
        Estatísticas por usuário
      </Text>

      <Space mt={2} />

      <Loader show={loadingViewersStatistics}>
        <TableList config={configList} data={viewersStatistics?.success?.viewersStatistics || []} />
      </Loader>
    </Container>
  );
};
