import { Switch, Route } from 'react-router-dom';

import List from './List';

export default () => {
  const BASE_URL = '/admin/room/details/:room/question-topic';

  return (
    <Switch>
      <Route
        path={[`${BASE_URL}`, `${BASE_URL}/new`, `${BASE_URL}/:html`]}
        component={List}
        exact
      />
    </Switch>
  );
};
