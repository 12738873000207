import styled from 'styled-components';
import { hooks, FormGrid, Button, TextInput, Select } from '@ederzadravec/jade-ui';
import { cpf } from 'cpf-cnpj-validator';

import { Modal } from 'components';
import { useService } from 'service';

const Container = styled(Modal)``;

const validations = {
  name: [[value => !value, 'Deve ser preenchido']],
  email: [
    [value => !value, 'Deve ser preenchido'],
    // [
    //   value => !value.match(/^[a-z0-9.]*@[a-z0-9]*\.[a-z]{2,3}(\.[a-z]{2,3})?$/i),
    //   'E-mail inválido',
    // ],
  ],
  cpf: [
    [value => !value, 'Deve ser preenchido'],
    [value => !cpf.isValid(value), 'CPF inválido'],
  ],
};

export const Form = ({ onClose }) => {
  const [, { loading }, saveUser] = useService('post', '/user', {}, false);
  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      cpf: form.getValue('cpf'),
    };

    const result = await saveUser(data);

    if (result?.success?.status === 'OK') {
      return onClose();
    }

    if (result.error) {
      return form.setError(result.error);
    }

    // Implementar Toaster
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'email',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'cpf',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'CPF',
          variant: 'cpf',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container size={{ md: 6 }} title="Cadastrar Usuário" onClose={onClose}>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={loading}>Enviar</Button>
      </FormGrid>
    </Container>
  );
};
