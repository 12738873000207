import { helpers } from '@ederzadravec/jade-ui';

export const theme = () => {
  const defaultTheme = helpers.getTheme();

  return {
    typography: {
      fontFamily: 'Anteb',
    },
    palette: {
      primary: {
        light: '#7F6EFF',
        main: '#7F6EFF',
        dark: '#7F6EFF',
        text: defaultTheme.palette.getContrastText('#7F6EFF', defaultTheme.palette.text),
      },
      secondary: {
        light: '#009CC0',
        main: '#009CC0',
        dark: '#009CC0',
        text: defaultTheme.palette.getContrastText('#009CC0', defaultTheme.palette.text),
      },
      tertiary: {
        light: '#FEAC0E',
        main: '#FEAC0E',
        dark: '#FEAC0E',
        text: defaultTheme.palette.getContrastText('#FEAC0E', defaultTheme.palette.text),
      },
      alert: {
        light: '#E66760',
        main: '#F51B0F',
        dark: '#992B29',
        text: defaultTheme.palette.getContrastText('#F51B0F', defaultTheme.palette.text),
      },
      white: {
        light: '#E8E8E8',
        main: '#ffffff',
        dark: '#B5B5B5',
        text: defaultTheme.palette.getContrastText('#ffffff', defaultTheme.palette.text),
      },
      dark: {
        light: '#E3E3E3',
        main: '#494440',
        dark: '#3D3936',
        text: defaultTheme.palette.getContrastText('#494440', defaultTheme.palette.text),
      },
      black: {
        light: '#f7f7f7',
        main: '#333333',
        dark: '#000000',
        text: defaultTheme.palette.getContrastText('#333333', defaultTheme.palette.text),
      },
      grey: {
        light: '#EDEFF0',
        main: '#d1d2d3',
        dark: '#6F7070',
        text: defaultTheme.palette.getContrastText('#d1d2d3', defaultTheme.palette.text),
      },
    },
    components: {
      button: {
        height: '40px',
        // width: '100%',
        borderRadius: '4px',
        borderWidth: '1',
        labelFontSize: '16px',
      },
      tableList: {
        headerFontSize: 16,
        itemFontSize: 14,
        border: '1px solid #bbb',
        borderRadius: 14,
      },
      textInput: {
        borderRadius: 10,
        labelLeft: 10,
        labelTop: 24,
        paddingInput: '8px 10px',
        heightInput: 36,
        borderType: 'border',
        marginBottom: 16,
      },
      textArea: {
        borderRadius: 14,
        labelLeft: 10,
        labelTop: 24,
        paddingInput: '8px 10px',
        borderType: 'border',
        marginBottom: 12,
      },
    },
  };
};
