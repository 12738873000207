import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

export const Container = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    height: 130px;
    background: ${({ theme }) => theme.palette.white.main};
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Header = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

export const Logo = styled.img`
  height: 60px;
  width: 160px;
  margin-right: 40px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0;
    margin-right: unset;
  }
`;

export const ContaintToggle = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Toggle = styled(FaBars)`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.black.main};
  cursor: pointer;
`;

export const LeftHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 32px;
    justify-content: space-between;
  }
`;

export const CenterHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 32px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 32px 16px;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 32px 0 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Wellcome = styled.div``;

export const Separator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.dark.main};
  width: 1px;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const ImageProfile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;
