import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaInstagram } from 'react-icons/fa';

import { Box, Text, Space, ListInstagram } from 'components';

import { ContextLanguage } from 'contexts';

const Container = styled(Box)`
  width: 100%;
  padding: 32px;
`;

const IconInstagram = styled(FaInstagram)`
  font-size: 22px;
`;

export const Instagram = () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
        <Text size="14" type="span" color="black" bold="500">
          <Space mt={0.3} />
          <IconInstagram />
        </Text>

        <Space ml={1} />

        <Text size="22" color="black" bold="500">
          INSTAGRAM
        </Text>
      </div>


      <Space mt={2} />

      {/* <Text finality="title" type="h" color="primary" >
        {cxtLang.lang === 'pt' && 'Momento Zenvia | Compartilhe sua foto'}
        {cxtLang.lang === 'en' && 'Zenvia Moment | Share your photo'}
        {cxtLang.lang === 'es' && 'Momento Zenvia | Comparte tu foto'}
      </Text> */}

      <Space mt={1} />

      <Text size="18" color="black">
        {cxtLang.lang === 'pt' && 'Utilize a '}
        {cxtLang.lang === 'en' && 'Use the '}
        {cxtLang.lang === 'es' && 'Utilizar el '}
        <Text size="18" color="secondary">
          #CONVENCAOTOTVS2022
        </Text>{' '}
        {cxtLang.lang === 'pt' && 'para ver sua foto em nosso mural'}
        {cxtLang.lang === 'en' && 'to see your photo on our wall'}
        {cxtLang.lang === 'es' && 'para ver tu foto en nuestro muro'}
      </Text>
      <br />

      <ListInstagram />
    </Container>
  );
};
