import React, { useContext, useState } from 'react';
import { hooks, FormGrid, TextInput, Button } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Space, Text } from 'components';
import { useService } from 'service';
import { ContextLanguage } from 'contexts';
import { textsFormRecover } from './Texts';

import { Container, ContainerButton } from './style';

const validations = {
  email: [[value => !value, 'Campo obrigatório']],
};

export const FormRecover = ({ setAction }) => {
  const [, { loading }, newpass] = useService('patch', `/app/viewer/newpass`, null, false);
  const [error, setError] = useState('');

  const cxtLang = useContext(ContextLanguage);

  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmitRecover = async () => {
    const data = {
      email: form.getValue('email').toLowerCase().replace(' ', ''),
      recover: true,
    };

    const result = await newpass(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: `${textsFormRecover[cxtLang.lang].successMessage}`,
        confirmButtonText: 'Ok',
      });
      setAction('welcome');
    }

    if (result?.error) {
      if (result?.error?.status === 'ERROR2') {
        setError('Verifique o e-mail digitado.');

        setInterval(() => {
          setError('');
        }, 3000);
      }
      return form.setError(result?.error);
    }
  };

  const formConfigRecover = [
    [
      {
        schema: 'email',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${textsFormRecover[cxtLang.lang].labelFieldEmail}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h" center>Recuperar acesso</Text>

      <FormGrid config={formConfigRecover} onSubmit={form.trySave(handleOnSubmitRecover)}>

        <Space mt={2} />

        <ContainerButton>
          <Button style={{ minWidth: '100%' }} color="primary" fontWeight={600} loading={loading}>
            {textsFormRecover[cxtLang.lang].labelButton}
          </Button>
        </ContainerButton>

        {error && (
          <>
            <Space mt={2} />
            <Text type="p" center>
              {error}
            </Text>
          </>
        )}
      </FormGrid>

      <Space mt={2} />

      <Text type="p" center>
        <Text
          color="secondary"
          width={100}
          fontWeight={600}
          type="link"
          size={14} 
          bold
          onClick={() => setAction('login')}>
          {textsFormRecover[cxtLang.lang].uriBackToLogin}
        </Text>
      </Text>
    </Container>
  );
};
