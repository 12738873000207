import { Switch, Route } from 'react-router-dom';

import List from './List';
import User from './user/User';

export default () => {
  const BASE_URL = '/admin/user';

  return (
    <Switch>
      <Route path={[`${BASE_URL}`, `${BASE_URL}/new`]} component={List} exact />
      <Route path={`${BASE_URL}/:id`} component={User} />
    </Switch>
  );
};
