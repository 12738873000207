import React from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';

import { Box, Text, Space } from 'components';
import { Store } from 'contexts';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContainerAward = styled(Box)`
  background: #dedede;
  width: 350px;
`;

const Link = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.dark.main};
`;

export default () => {
  const [showAward, setShowAward] = React.useState(false);
  const { user } = React.useContext(Store.Context);

  const [viewersData, { loading }, refreshViewers] = useService('get', '/viewer/detach');

  const [dataAward] = useService('get', `/award/get-award/${user.id}`, {}, true, [user.id]);
  const [, {}, setRescued] = useService('patch', `/award/set-rescued-award/${user.id}`, {}, false);

  const award = dataAward?.success?.award || [];

  return (
    <Container>
      <Box>
        <Text type="h" size="22" bold color="primary">
          VOUCHERS
        </Text>

        <Space mt={2} />

        <Text type="p" size="16">
          Olá time Comercial e Serviços!
          <br />
          <br />
          Se você recebeu um voucher no kit de boas vindas, você precisa saber.
        </Text>

        <Space mt={1} />

        <Text type="h" size="18" bold color="primary">
          VOCÊ TEM BASTANTE SORTE! PARABÉNS!
        </Text>

        <Space mt={2} />

        <Text type="h" size="16" bold color="dark">
          Confira a seguir o regulamento e aproveite seu prêmio:
        </Text>

        <Space mt={2} />

        <Text type="p" size="16" color="dark">
          - O vale-presente é nominal e instransferível e te dá acesso ao seu prêmio. <br />
          - A Next Frontiers entrará em contato por e-mail para informar como você deverá proceder
          para realizar o seu resgate. <br />
          - Você tem até o dia 26 de fevereiro de 2021 para efetuar o resgate. Após esta data, o
          vale expira e você perde o direito de usufruir do seu benefício. <br />- A Next Frontiers
          não se responsabiliza pela qualidade do produto adquirido e por eventuais problemas com a
          loja.
        </Text>

        <Space mt={2} />

        <Text type="h" finality="title" color="primary">
          ESPERAMOS QUE VOCÊ APROVEITE SEU PRESENTE! <br /> SUCESSO!
        </Text>

        <Space mt={3} />

        <Button onClick={() => setShowAward(!showAward)}>Resgatar</Button>

        <Space mt={3} />
        {showAward && (
          <>
            {award?.award && !award?.rescued ? (
              <ContainerAward>
                <Text type="h" size="22" bold color="primary">
                  PARABÉNS
                </Text>

                <Space mt={2} />

                <Text type="p" size="16" color="dark">
                  Você ganhou:
                </Text>
                <Text type="p" size="16" color="dark" bold="500">
                  {award?.award}
                </Text>

                <Space mt={1} />

                <Text type="p" size="16" color="dark">
                  {award?.description}
                </Text>

                <Space mt={1} />

                {award?.link && (
                  <Link href={award?.link} target="_blank">
                    {award?.link}
                  </Link>
                )}
              </ContainerAward>
            ) : (
              <ContainerAward>
                <Text type="h" size="22" bold color="alert">
                  NÃO FOI DESSA VEZ :/
                </Text>

                <Space mt={2} />

                <Text type="p" size="16" color="dark">
                  Infelizmente, não existem vale-presentes atrelados a sua conta. Em caso de dúvidas
                  entre em contato com eventos@totvs.com.br.
                </Text>
              </ContainerAward>
            )}
          </>
        )}
      </Box>
      <br />
    </Container>
  );
};
