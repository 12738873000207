import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 786px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const Body = styled.div``;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

export const ListImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  flex-direction: row;
  max-height: 600px;
  overflow: auto;
`;

export const ContainerImage = styled.div`
  display: flex;
  width: calc((100% / 5) - 16px);
  margin-right: 16px;
  margin-bottom: 16px;

  @media (max-width: 1367px) {
    width: calc((100% / 5) - 16px);
  }

  @media (max-width: 786px) {
    width: calc((100% / 2) - 16px);
  }
`;

export const Image = styled.img`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

export const ButtonControlPage = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  ${({ noSize }) =>
    noSize &&
    css`
      width: unset;
      height: unset;
      border-radius: unset;
      padding: 5px 10px;
      border-radius: 15px;
    `}
`;
