import React from 'react';
import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';

import { Icon } from '../Icon/Icon';
import { Text } from '../Text';

const Mask = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transition: easy in 0.4s;
  background: rgba(0, 0, 0, 0.75);
  padding: 0 48px;
`;

const Container = styled(Grid)`
  position: relative;
  flex-direction: column;
  background: #fff;
  max-height: 80%;
  padding: 16px 0;
  border-radius: 14px;
  
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Title = styled(Text)`
  width: 100%;
  margin-bottom: 8px;
  padding: 0 24px;
`;

const Content = styled.div`
  overflow: auto;
  padding: 16px 24px;
`;

const Close = styled(Text)`
  cursor: pointer;
  position: absolute;
  top: -50px;
  right: 10px;
`;

export const Modal = ({ onClose, size, title, children, ...props }) => {
  React.useEffect(() => {
    document.onkeydown = e => {
      if (e.key === 'Escape') onClose();
    };
  }, []);

  return (
    <Mask container onClick={onClose} spacing={0}>
      <Container size={size} onClick={e => e.stopPropagation()} spacing={0}>
        <Close color="primary" type="span" size="30" bold onClick={onClose}>
          X
        </Close>

        {title && (
          <Title size="22" type="h" bold color="primary">
            {title}
          </Title>
        )}

        <Content {...props}>{children}</Content>
      </Container>
    </Mask>
  );
};

Modal.defaultProps = {
  size: { md: 6 },
};
