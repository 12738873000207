import React from 'react';
import { useParams } from 'react-router-dom';
import { hooks, FormGrid, TextInput, Loader, Button } from '@ederzadravec/jade-ui';

import { Space, Modal } from 'components';
import { useService } from 'service';

const validations = {
  namePt: [[value => !value, 'Campo obrigatório']],
};

export const FormQuestion = ({ onClose, id_room, BASE_URL }) => {
  let { topic: idTopic } = useParams();

  const [form, onChange] = hooks.useForm({
    validations,
  });
  const [, { loading: htmlLoading }, fetchTopic] = useService(
    'get',
    `/room/${id_room}/question-topic/${idTopic}`,
    {},
    false
  );
  const [, { loading: saveLoading }, saveTopic] = useService(
    'post',
    `/room/${id_room}/question-topic`,
    {},
    false
  );
  const [, { loading: updateLoading }, updateTopic] = useService(
    'patch',
    `/room/${id_room}/question-topic/${idTopic}`,
    {},
    false
  );

  React.useEffect(() => {
    if (!idTopic) return;

    fetchTopic()
      .then(data => {
        const topic = data?.success?.topic || [];

        form.setValues({
          namePt: topic?.name?.pt,
          nameEn: topic?.name?.en,
          nameEs: topic?.name?.es,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const formConfig = [
    [
      {
        schema: 'namePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  const handleOnSave = async () => {
    const data = {
      namePt: form.getValue('namePt'),
      nameEn: form.getValue('nameEn'),
      nameEs: form.getValue('nameEs'),
    };

    const result = form.getValue('_id') ? await updateTopic(data) : await saveTopic(data);

    if (result?.success?.status === 'OK') {
      onClose(true);
    }

    if (result?.error) {
      console.log('euu', 'Deu ruim');
    }
  };

  return (
    <Modal size={{ md: 6 }} onClose={() => onClose(false)} title="Formulário de Tópico">
      <Loader show={htmlLoading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSave)}>
          <Space mt={5} />

          <Button loading={updateLoading || saveLoading}>Salvar</Button>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
