import styled from 'styled-components';
import { Grid, Loader, Button } from '@ederzadravec/jade-ui';
import { useParams } from 'react-router-dom';

import { Text, Space } from 'components';
import { useService } from 'service';
import { Room } from 'contexts';

import { Interactions, FormChat, ResumeRoom } from './components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
  padding-top: 24px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fafafb;
  border-radius: 10px;
  min-height: 250px;
  max-height: 600px;
  overflow: hidden;
`;

const ContainerEvaluation = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

const UpdateSetEvaluation = () => {
  const { room: idRoom } = useParams();

  const [dataRoom, { loading }, fetchRoom] = useService('get', `/room/${idRoom}`);

  const room = dataRoom?.success?.room || [];

  const [, {}, updateSetEvaluation] = useService(
    'patch',
    `room/${idRoom}/evaluation/active`,
    {},
    false
  );

  const handleOnSetStatus = async visible => {
    await updateSetEvaluation({
      isVisibleEvaluation: !visible,
    });

    fetchRoom();
  };

  return (
    <ContainerEvaluation>
      <Text type="h" size="22" color="primary">
        Avaliação
      </Text>

      <Space mt={2} />

      <Button loading={loading} onClick={() => handleOnSetStatus(room?.isVisibleEvaluation)}>
        {room?.isVisibleEvaluation ? 'Visivel' : 'Oculto'}
      </Button>
    </ContainerEvaluation>
  );
};

export default ({ match }) => {
  const [dataRoom, { loading }] = useService('get', `/room/${match.params.room}`);
  
  const idRoom = match?.params?.room;
  
  const room = dataRoom?.success?.room || [];
  console.log("eee", room)

  return (
    <Room.Provider room={idRoom}>
      <Container>
        <Loader show={loading} direction="column">
          <Header>
            <Text type="h" size="24" bold color="dark">
              Sala - {room?.name?.pt}
            </Text>
          </Header>

          <Grid container>
            <Grid size={{ md: 6 }}>
              <Content>
                <Interactions />
              </Content>
            </Grid>

            <Grid size={{ md: 6 }}>
              <Content>
                <FormChat idRoom={idRoom} />
              </Content>
            </Grid>
          </Grid>

          <Space mt={3} />

          <Grid container>
            <Grid size={{ md: 12 }}>
              <ResumeRoom idRoom={idRoom} />
            </Grid>
          </Grid>

          <Space mt={3} />

          <Grid container>
            <Grid size={{ md: 2 }}>
              <UpdateSetEvaluation />
            </Grid>
            <Grid size={{ md: 10 }}></Grid>
          </Grid>
        </Loader>
      </Container>
    </Room.Provider>
  );
};
