import React from 'react';

import { Store } from 'contexts';

export default ({ history }) => {
  const { setStore } = React.useContext(Store.Context);

  setStore({ user: {}, session: '' });

  localStorage.removeItem('session');

  history.push('/');

  return null;
};
