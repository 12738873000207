import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { Item } from './Item';

const Container = styled.div`
  display: flex;
  position: fixed;
  height: max-content;
  width: max-content;
  right: 0;
  top: 0;
  flex-direction: column;
  z-index: 2000;
  flex-wrap: wrap-reverse;
`;

let handleOpen = () => {};

const Component = () => {
  const [items, setItems] = React.useState([]);

  const onClose = internalIndex => e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setItems(items => {
      const index = items.findIndex(x => x.index === internalIndex);

      const item = items[index];

      clearInterval(item?.interval);

      return [...R.remove(index, 1, items)];
    });
  };

  handleOpen = ({ title, time, link, labelButtom, onClick }) => {
    setItems(items => {
      const index = new Date() / 1000;
      const interval = setTimeout(onClose(index), time || 8000);

      return [
        ...items,
        {
          index,
          title,
          onClick,
          link,
          labelButtom,
          interval,
        },
      ];
    });
  };

  return (
    <Container>
      {items.map((item, key) => (
        <Item {...item} onClose={onClose(key)} />
      ))}
    </Container>
  );
};

export const Toastr = {
  Component,
  open: (...props) => handleOpen(...props),
};
