import React, { useContext } from 'react';
import styled from 'styled-components';

import EN from 'assets/images/flags/united_states_flag.png';
import PT from 'assets/images/flags/brazil_flag.png';
import ES from 'assets/images/flags/spain_flag.png';

import { ContextLanguage } from 'contexts';

const ImageFlag = styled.img`
  width: 40px;
`;

export const Flags = () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        top: '20px',
        right: '30px',
      }}>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => cxtLang.setLang('pt')}>
        <ImageFlag src={PT} />
      </span>

      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => cxtLang.setLang('es')}>
        <ImageFlag src={ES} />
      </span>

      {/* <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => cxtLang.setLang('en')}>
        <ImageFlag src={EN} />
      </span> */}
    </div>
  );
};
