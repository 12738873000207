import React, { useState } from 'react';
import { Button, Loader } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { List } from './List';
import { useService } from 'service';

import { Container, Header, Content, ContentButtons } from './styles';

export default ({ history }) => {
  const [mode, setMode] = useState('initial');
  const [dataByInstagram, { loading }, fetchByHash] = useService(
    'get',
    `/instagram/get-posts?limit=1000`,
    {},
    false
  );
  const [dataByDb, { loadingByDb }, fetchByDb] = useService(
    'get',
    `/instagram?limit=1000`,
    {},
    false
  );

  const handleOnSearch = async () => {
    setMode('search');
    fetchByHash();
  };

  const handleOnSearchInDb = async () => {
    setMode('manager');
    fetchByDb();
  };

  const dataSearch = dataByInstagram?.success?.posts?.data || [];
  const dataManager = dataByDb?.success?.posts?.data || [];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Instagram
        </Text>

        <ContentButtons>
          <Button onClick={() => handleOnSearch()}>
            {loading ? 'Buscando..' : 'Buscar no Instagram'}
          </Button>

          <Button onClick={() => handleOnSearchInDb()}>
            {loadingByDb ? 'Buscando..' : 'Buscar no Banco'}
          </Button>
        </ContentButtons>
      </Header>

      <Content>
        <Loader show={loading}>
          {dataByInstagram?.success?.posts?.data?.length === 0 ? (
            'Sem posts'
          ) : (
            <List data={mode === 'manager' ? dataManager : dataSearch} mode={mode} />
          )}
        </Loader>
      </Content>
    </Container>
  );
};
