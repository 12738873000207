import React from 'react';
import {
  hooks,
  FormGrid,
  Button,
  TextInput,
  TextArea,
  Space,
  Loader,
  Select,
} from '@ederzadravec/jade-ui';

import { Modal } from 'components';
import { useService } from 'service';
import { isNotValidDate, dateToDb, dbToDate } from 'utils/date';
import { converge } from 'ramda';

const validations = {
  award: [[value => !value, 'Deve ser preenchido']],
};

export const Award = ({ match, history, onClose }) => {
  const BASE_URL = '/admin/award';
  const id_award = match?.params?.award;

  const [, { loading }, saveAward] = useService('post', '/award', {}, false);
  const [dataViewers, { loading: roomLoadingViewers }, fetchViewers] = useService(
    'get',
    `award/viewers?limit=5000`,
    {},
    true
  );

  const VIEWERS = dataViewers?.success?.viewers?.data || [];

  const [, { loading: awardLoading }, fetchAward] = useService(
    'get',
    `award/${id_award}`,
    {},
    false,
    [id_award]
  );
  const [, { loading: updateLoading }, updateAward] = useService(
    'patch',
    `/award/${id_award}`,
    {},
    false,
    [id_award]
  );

  const [form, onChange] = hooks.useForm({ validations });

  id_award &&
    React.useEffect(() => {
      if (!id_award) {
        return;
      }

      fetchAward().then(data => {
        if (data?.error) {
          console.log('euu', 'deu ruim');
        }

        if (data?.success?.status === 'OK') {
          const award = data?.success?.award;

          form.setValues(award);
        }
      });
    }, []);

  const handleOnSubmit = async () => {
    const data = {
      viewer: form.getValue('viewer')._id,
      award: form.getValue('award'),
      description: form.getValue('description'),
      link: form.getValue('link'),
    };

    const result = form.getValue('_id') ? await updateAward(data) : await saveAward(data);

    if (result?.success?.status === 'OK') {
      form.clear();

      history.push(`${BASE_URL}`);
    }

    if (result?.error?.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'viewer',
        size: { md: 12 },
        type: Select,
        props: schema => ({
          label: 'Usuário',
          data: VIEWERS,
          value: form.getValue(schema),
          error: form.getError(schema),
          config: { value: '_id', label: 'name' },
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'award',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Prêmio',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'description',
        size: { md: 12 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'link',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Link',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Modal
      size={{ md: 6 }}
      title="Cadastro de Prêmio"
      onClose={() => {
        history.push(BASE_URL);
      }}>
      <Loader show={roomLoadingViewers || awardLoading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <Space top={3}>
            <Button loading={loading || updateLoading}>Enviar</Button>
          </Space>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
