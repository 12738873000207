import React from 'react';

import * as S from './style';

export const Text = ({ children, type, onClick, ...props }) => {
  return (
    <>
      {type === 'p' && <S.P {...props}>{children}</S.P>}

      {type === 'span' && <S.Span {...props}>{children}</S.Span>}

      {type === 'link' && (
        <S.Link {...props} onClick={onClick}>
          {children}
        </S.Link>
      )}

      {type === 'h' && <S.H1 {...props}>{children}</S.H1>}
    </>
  );
};

Text.defaultProps = {
  type: 'span',
  color: '',
  variant: '',
};
