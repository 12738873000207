import React from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, TextInput } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Space, Text, Button } from 'components';
import { useService } from 'service';

const Container = styled.div`
  padding: 24px 0;
`;

const validations = {};

export const FormRecover = ({ setIsRecover }) => {
  const [, { loading }, newpass] = useService('patch', `viewer/newpass`, null, false);

  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmitRecover = async () => {
    const data = {
      email: form.getValue('email'),
      recover: true,
    };

    const result = await newpass(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title:
          'Uma nova senha será enviada ao seu email dentro de alguns minutos! (Por favor,  verifique também sua caixa de spam.)',
        confirmButtonText: 'Ok',
      });
      setIsRecover(false);
    }

    if (result?.error) {
      return form.setError(result?.error);
    }
  };

  const formConfigRecover = [
    [
      {
        schema: 'email',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <FormGrid config={formConfigRecover} onSubmit={form.trySave(handleOnSubmitRecover)}>
        <Space mt={2} />

        <Button loading={loading} center>
          REDEFINIR
        </Button>
      </FormGrid>

      <Space mt={2} />

      <Text type="p" center>
        <Text color="primary" type="link" bold onClick={() => setIsRecover(false)}>
          Ir para Login
        </Text>
      </Text>
    </Container>
  );
};
