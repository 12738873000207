import React from 'react';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Text, Space } from 'components';
import service, { useService } from 'service';
import { dbToDate } from 'utils/date';
import { Store } from 'contexts';

import { Container, Content, Header } from './style.js';
import { useState } from 'react';

export default ({ history, location }) => {
  const [refresh, setRefresh] = useState('');
  const [roomsData, { loading }, refreshSpeakers] = useService('get', '/room?limit=100', {}, true, [
    refresh,
  ]);

  const { session, type: sessionType } = React.useContext(Store.Context);

  const BASE_URL = '/admin/room';

  const handleOnDelete = roomId => {
    Swal.fire({
      title: 'Deseja mesmo seguir com a exclusão?',
      text: 'Não sera possivel reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, remover agora!',
    }).then(result => {
      if (result.isConfirmed) {
        (async () => {
          const result = await service.delete(`/room/${roomId}`, {
            headers: {
              authorization: session,
              type: sessionType,
            },
          });

          if (result?.data?.success?.status === 'OK') {
            Swal.fire('Removida!', 'Esta sala foi removida com sucesso!', 'success');
            setRefresh(String(new Date()));
          } else {
            Swal.fire('Ops.!', 'Algo deu errado ao tentar remover!', 'warning');
          }
        })();
      }
    });
  };

  const handleOnSelectItem = item => {
    history.push(`${BASE_URL}/details/${item._id}/talk`);
  };

  const handleFormClose = () => {
    history.push(BASE_URL);
    refreshSpeakers();
  };

  const configList = [
    {
      name: 'Data',
      dataPath: data => dbToDate(data.openAt),
      size: {
        md: 2,
      },
    },
    {
      name: 'Sala',
      dataPath: 'name.pt',
      size: {
        md: 3,
      },
    },
    {
      name: 'Abertura',
      dataPath: data => dbToDate(data.openAt, 'dd/MM/yyyy - HH:mm'),
      size: {
        md: 2,
      },
    },
    {
      name: 'Fechamento',
      dataPath: data => dbToDate(data.closeAt, 'dd/MM/yyyy - HH:mm'),
      size: {
        md: 2,
      },
    },
    {
      name: 'Palestras',
      dataPath: data => data?.lectures?.length || 0,
      size: {
        md: 1,
      },
    },
    {
      name: 'Ação',
      dataPath: data => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            type="link"
            size="16"
            bold
            color="dark"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`${BASE_URL}/edit/${data._id}`);
            }}>
            Editar
          </Text>

          <Space ml={1} />

          <Text
            type="link"
            size="16"
            bold
            color="dark"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOnDelete(data._id);
              // history.push(`${BASE_URL}/edit/${data._id}`);
            }}>
            Remover
          </Text>
        </div>
      ),
      size: {
        md: 2,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Salas
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Nova
        </Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={roomsData?.success?.rooms?.data || []}
            onSelect={handleOnSelectItem}
          />
        </Content>
      </Loader>

      <Switch>
        <Route
          path={[`${BASE_URL}/new`, `${BASE_URL}/:room`]}
          component={props => <Form {...props} onClose={handleFormClose} />}
        />
      </Switch>
    </Container>
  );
};
