import React, {useContext} from 'react';

import { Container, Image } from './style';
import { Box } from 'components';

import Image1br from 'assets/images/temp/agenda1_br.png';
import Image1es from 'assets/images/temp/agenda1_es.png';
import { ContextLanguage } from 'contexts';


export default () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Box>
        {cxtLang.lang === 'pt' && <Image src={Image1br} />}
        {cxtLang.lang === 'en' && ''}
        {cxtLang.lang === 'es' && <Image src={Image1es} />}
      </Box>
    </Container>
  );
};
