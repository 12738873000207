import React from 'react';
import styled from 'styled-components';

const Container = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  fill: ${({ color, theme }) => theme.palette[color].main}!important;
`;

export const Icon = ({ name, ...props }) => {
  const IconSVG = require(`assets/icons/${name}.svg`).default;

  return <Container src={IconSVG} {...props} />;
};

Icon.defaultProps = {
  name: 'dashboard',
  color: 'black',
  size: 24,
};
