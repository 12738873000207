import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

import { ListInstagram } from 'components';

import { HeaderPage, Rooms, BannerSponsorBig, BannerSponsorMedium } from './components';

const Dashboard = () => {
  return (
    <>
      <HeaderPage />

      <Rooms />

      <BannerSponsorBig />

      <BannerSponsorMedium />

      <ListInstagram />
    </>
  );
};

export default () => {
  const BASE_URL = '/app';

  return (
    <Container>
      <Switch>
        <Route path={[`${BASE_URL}`]} component={Dashboard} />
      </Switch>
    </Container>
  );
};
