import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Tab = styled.div`
  flex: ${({ size }) => size};
  text-align: center;
  padding: 16px 0;
  font-weight: bold;
  font-size: 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
  cursor: pointer;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.5s;
  background: #fff;

  ${({ active, theme }) =>
    !active
      ? `
      color: ${({ theme }) => theme.palette.primary.dark};
      background: #eee;
      border: 1px solid #fff;
    `
      : `
      color: ${theme.palette.primary.main};
      border: 1px solid ${theme.palette.primary.main};
      border-bottom: 0px;
      box-shadow: 0px -3px  3px 1px ${theme.palette.primary.light}40;
    `}
`;

export const Tabs = ({ config }) => {
  return (
    <Container>
      {config.map(tab => (
        <Tab size={config.length} onClick={tab.onClick} active={tab.active()}>
          {tab.label}
        </Tab>
      ))}
    </Container>
  );
};
