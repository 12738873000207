export const selectText = (lang, textPt, textEn, textEs) => {
  switch (lang) {
    case 'pt':
      return textPt;
    case 'en':
      return textEn;
    case 'es':
      return textEs;

    default:
      return '';
  }
};
