import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { TableList, Loader, Button } from '@ederzadravec/jade-ui';
import { FaCheckDouble, FaCheck } from 'react-icons/fa';

import { FormQuestion, Text, Modal } from 'components';
import { ListQuestions } from './';
import { useService } from 'service';

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
`;

const IconVisible = styled(FaCheckDouble)`
  margin: 0 0 0 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const IconNotVisible = styled(FaCheck)`
  margin: 0 0 0 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.dark.main};
`;

const LabelAction = styled(Text)`
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
  cursor: pointer;
`;

const ModalQuestions = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Text type="link" size="16" onClick={() => setShowModal(!showModal)}>
        Exibir
      </Text>
      {showModal && (
        <Modal title="Gerenciamento de Respostas" onClose={() => setShowModal(!showModal)}>
          <ListQuestions idTopic={data?._id} />
        </Modal>
      )}
    </>
  );
};

const UpdateForm = ({ data, refresh }) => {
  const RouteParams = useParams();
  const [, { loading }, updateVisibility] = useService(
    'patch',
    `/room/${RouteParams.room}/question-topic/${data._id}/active`,
    {},
    false
  );

  const handleOnIsVisibility = async (id, visible) => {
    await updateVisibility({
      _id: id,
      isVisible: true,
      isVisibleForm: !visible,
    });

    refresh();
  };

  if (loading) return null;

  if (!data.isVisible) return null;

  return (
    <LabelAction
      type="link"
      color="dark"
      center
      onClick={() => handleOnIsVisibility(data._id, data.isVisibleForm)}>
      {!data.isVisibleForm ? <IconNotVisible /> : <IconVisible />}
    </LabelAction>
  );
};

const Update = ({ data, refresh }) => {
  const RouteParams = useParams();
  const [, { loading }, updateVisibility] = useService(
    'patch',
    `/room/${RouteParams.room}/question-topic/${data._id}/active`,
    {},
    false
  );

  const handleOnIsVisibility = async (id, visible) => {
    await updateVisibility({
      _id: id,
      isVisible: !visible,
      isVisibleForm: !visible,
    });

    refresh();
  };

  if (loading) return null;

  return (
    <LabelAction color="dark" type='link' onClick={() => handleOnIsVisibility(data._id, data.isVisible)}>
      {!data.isVisible ? <IconNotVisible /> : <IconVisible />}
    </LabelAction>
  );
};

export const Question = () => {
  const [showForm, setShowForm] = React.useState(false);

  const RouteParams = useParams();

  const [topicsData, { loading }, fetchTopics] = useService(
    'get',
    `/room/${RouteParams.room}/question-topic`
  );

  const handleOnCloseForm = refresh => {
    setShowForm(false);

    if (refresh) fetchTopics();
  };

  const listConfig = [
    {
      name: 'Name',
      size: { md: 6 },
      dataPath: 'name.pt',
    },
    {
      name: 'Ação',
      size: { md: 2 },
      dataPath: data => <Update data={data} refresh={fetchTopics} />,
    },
    {
      name: 'Form',
      size: { md: 2 },
      dataPath: data => <UpdateForm data={data} refresh={fetchTopics} />,
    },
    {
      name: 'Perguntas',
      size: { md: 2 },
      dataPath: data => <ModalQuestions data={data} />,
    },
  ];

  const topics = topicsData?.success?.topics?.data || [];

  return (
    <>
      <Loader show={loading} direction="column">
        <TableList config={listConfig} data={topics} />
      </Loader>

      <Footer>
        <Button onClick={() => setShowForm(true)}>Novo</Button>
      </Footer>

      {showForm && <FormQuestion onClose={handleOnCloseForm} id_room={RouteParams.room} />}
    </>
  );
};
