import React from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { CardDetail } from './CardDetail';
import { CardRoom } from './CardRoom';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 0;
`;

export default ({ history, match }) => {
  const [showDetail, setShowDetail] = React.useState(null);
  const [schedule, setSchedule] = React.useState(false);
  const idRoom = match?.params?.room;

  const [dataRoom, { loading }, fetchRoom] = useService('get', `/app/room/${idRoom}`, {}, true, [
    idRoom,
  ]);

  const data = dataRoom?.success?.room || [];

  return (
    <Container>
      <Loader loading={loading}>
        {showDetail === data._id && (
          <CardDetail
            data={data}
            setShowDetail={setShowDetail}
            refreshList={fetchRoom}
            schedule={schedule}
            setSchedule={setSchedule}
          />
        )}
        <CardRoom
          data={data}
          setShowDetail={setShowDetail}
          schedule={schedule}
          setSchedule={setSchedule}
        />
      </Loader>
    </Container>
  );
};
