import React, { useContext } from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, TextInput, Button } from '@ederzadravec/jade-ui';

import { Text, Space, Toastr } from 'components';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const validations = {
  currentPassword: [[value => !value, 'Campo obrigatório']],
  newPassword: [[value => !value, 'Campo obrigatório']],
  rePassword: [
    [value => !value, 'Campo obrigatório'],
    [(value, form) => value !== form.newPassword, 'Senhas divergentes'],
  ],
};

const thisTexts = {
  es: {
    settingsTextPassword: 'Contraseña y seguridad',
    settingsLabelPasswordCurrent: 'Contraseña Actual',
    settingsLabelPasswordNew: 'Ingrese la nueva contraseña',
    settingsLabelConfirmNew: 'Confirmar nueva contraseña',
    settingsButtonPassword: 'Guardar',
  },
  en: {
    settingsTextPassword: 'Password and Security',
    settingsLabelPasswordCurrent: 'Current password',
    settingsLabelPasswordNew: 'Enter the new password',
    settingsLabelConfirmNew: 'Confirm New Password',
    settingsButtonPassword: 'Save',
  },
  pt: {
    settingsTextPassword: 'Senha e Segurança',
    settingsLabelPasswordCurrent: 'Senha Atual',
    settingsLabelPasswordNew: 'Insira a Nova Senha',
    settingsLabelConfirmNew: 'Confirme a Nova Senha',
    settingsButtonPassword: 'Salvar',
  },
};

export const FormPassword = ({ idUser }) => {
  const [form, onChange] = hooks.useForm({ validations });

  const [, { loading: LoadingPass }, updatePass] = useService(
    'patch',
    `viewer/${idUser}/newpass`,
    {},
    false
  );
  
  const cxtLang = useContext(ContextLanguage);

  const handleOnSubmit = async () => {
    const data = {
      currentPassword: form.getValue('currentPassword'),
      newPassword: form.getValue('newPassword'),
      rePassword: form.getValue('rePassword'),
    };

    
    const result = await updatePass(data);
    
    if (result?.success?.status === 'OK') {
      Toastr.open({
        title: 'Senha Atualizada',
        labelButtom: 'Ok',
      });
      form.clear();
    }

    if (result?.error) {
      if (result?.error?.msg) {
        alert(result?.error?.msg);
      }
      console.log('euu', 'Deu ruim');
    }
  };

  const formConfig = [
    [
      {
        schema: 'currentPassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: `${thisTexts[cxtLang.lang].settingsLabelPasswordCurrent}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'newPassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: `${thisTexts[cxtLang.lang].settingsLabelPasswordNew}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'rePassword',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          type: 'password',
          label: `${thisTexts[cxtLang.lang].settingsLabelConfirmNew}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h" size="22" color="black">
      {thisTexts[cxtLang.lang].settingsTextPassword}
      </Text>

      <Space mt={1} />

      <hr />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={LoadingPass} color="primary">
          {thisTexts[cxtLang.lang].settingsButtonPassword}
        </Button>
      </FormGrid>
    </Container>
  );
};
