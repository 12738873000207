import React, { useState, useEffect, useRef } from 'react';

import { Container, Label, LabelError, Input, IconIsVisible } from '../inputs.style';

export const InputPassword = ({ label, error, disabled, value: valueProp, onChange, ...props }) => {
  const inputRef = useRef(null);
  const [isShowPass, setIsShowPass] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const handleOnblur = () => {
    if (value === '') {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };

  useEffect(() => {
    if (value !== valueProp) {
      setValue(valueProp ? valueProp : '');
      setFocus(true);
    }
  }, []);

  const handleOnChange = e => {
    setValue(e.target.value);
  };

  return (
    <Container>
      <Label
        {...props}
        error={error}
        focus={focus}
        onClick={
          disabled
            ? () => {}
            : () => {
                setFocus(true);
                inputRef.current.focus();
              }
        }>
        {label}
      </Label>

      {error && (
        <LabelError {...props} focus={focus}>
          {error}
        </LabelError>
      )}

      <Input
        {...props}
        ref={inputRef}
        type={isShowPass ? 'text' : 'password'}
        value={value}
        disabled={disabled}
        error={error}
        onInput={handleOnChange}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={handleOnblur}
      />

      <IconIsVisible onClick={() => setIsShowPass(!isShowPass)} />
    </Container>
  );
};

InputPassword.defaultProps = {
  onChange: () => {},
};
