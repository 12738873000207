import { useState } from 'react';
import styled from 'styled-components';

import { Button, Checkbox, Select, InputText, Space, Text } from 'components';

import { useService } from '../../../service';

const ContainerFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  & > div:first-child {
    width: 60%;
  }
  & > div:last-child {
    width: 38%;
  }
`;

const dataSelect = [
  {
    label: 'BMS',
    value: 'BMS',
  },
  {
    label: 'JANSSEN',
    value: 'JANSSEN',
  },
  {
    label: 'LIBBS',
    value: 'LIBBS',
  },
  {
    label: 'MSD',
    value: 'MSD',
  },
  {
    label: 'AMGEN',
    value: 'AMGEN',
  },
  {
    label: 'ROCHE',
    value: 'ROCHE',
  },
  {
    label: 'ZODIAC',
    value: 'ZODIAC',
  },
  {
    label: 'ASTELLAS',
    value: 'ASTELLAS',
  },
  {
    label: 'DAIICHI + ASTRAZENECA',
    value: 'DAIICHI + ASTRAZENECA',
  },
  {
    label: 'PFIZER',
    value: 'PFIZER',
  },
  {
    label: 'SANDOZ',
    value: 'SANDOZ',
  },
  {
    label: 'TAKEDA',
    value: 'TAKEDA',
  },
  {
    label: 'ABBVIE',
    value: 'ABBVIE',
  },
  {
    label: 'GE',
    value: 'GE',
  },
  {
    label: 'GSK',
    value: 'GSK',
  },
  {
    label: 'MERCK',
    value: 'MERCK',
  },
  {
    label: 'NOVARTIS',
    value: 'NOVARTIS',
  },
  {
    label: 'SANOFI',
    value: 'SANOFI',
  },
  {
    label: 'VARIAN',
    value: 'VARIAN',
  },
  {
    label: 'QIAGEN',
    value: 'QIAGEN',
  },
];

export const FormModal = ({ handleOnPrivacy }) => {
  const [check, setCheck] = useState(false);
  const [cognizant, setCognizant] = useState(false);
  const [data, setData] = useState({
    name: '',
    email: '',
    board: '',
    numberBoard: '',
    sponsors: '',
  });

  const [error, setError] = useState({
    name: '',
    board: '',
  });

  const [, { loading }, postTermsAccepted] = useService(
    'post',
    'https://api-hello-new-features-zjumg.ondigitalocean.app/room-terms-accepted',
    {},
    false
  );

  const handleOnSubmit = async () => {
    if (data.name === '') {
      setError({ ...error, name: 'Esse campo deve ser preenchido!' });

      return;
    } else {
      setError({ ...error, name: '' });
    }
    if (data.board === '') {
      setError({ ...error, board: 'Esse campo deve ser preenchido!' });

      return;
    } else {
      setError({ ...error, board: '' });
    }

    // const result = await postTermsAccepted(data);

    // if (result.status === 'OK') {
    handleOnPrivacy(true);
    // }

    // if (result.status === 'ERROR') {
    //   console.log('eee', 'deu ruim');
    // }
  };

  return (
    <>
      <Text color="dark" type="p" size="16">
        Algumas aulas desta sala são de acesso restrito a prescritores e dispensadores de
        medicamentos. Em cumprimento às diretrizes da RDC no. 96/08 da ANVISA, apenas profissionais
        habilitados poderão assisti-las, mediante a confirmação do Nº de registro no respectivo
        Conselho profissional, quando solicitado. São eles: CRM, CRMV, CRO ou CRF. Agradecemos a
        compreensão.
      </Text>

      <Space mt={2} />

      <InputText
        label="Nome"
        placeholder="Nome"
        value={data.name}
        error={error.name}
        onChange={e => {
          setData({
            ...data,
            name: e.target.value,
          });
        }}
      />

      <Space mt={2} />

      <InputText
        label="E-mail"
        placeholder="E-mail"
        value={data.email}
        error={error.email}
        onChange={e => {
          setData({
            ...data,
            email: e.target.value,
          });
        }}
      />

      <Space mt={2} />

      <InputText
        label="Conselho (Sigla)"
        placeholder="Conselho (Sigla)"
        value={data.board}
        onChange={e => {
          setData({
            ...data,
            board: e.target.value,
          });
        }}
      />

      <Space mt={2} />

      <ContainerFields>
        {!check ? (
          <InputText
            label="Nº Conselho/Estado"
            placeholder="Nº Conselho/Estado"
            value={data.numberBoard}
            onChange={e => {
              setData({
                ...data,
                numberBoard: e.target.value,
              });
            }}
          />
        ) : (
          <Select
            label="Patrocinadores"
            value={data.sponsors}
            data={dataSelect}
            onChange={e => {
              setData({
                ...data,
                sponsors: e.value,
              });
            }}
          />
        )}

        <Space mt={1} />

        <Checkbox
          label="Não possuo Nº de conselho"
          checked={check}
          onClick={() => {
            setCheck(!check);
          }}
        />
      </ContainerFields>

      <Space mt={2} />

      <Checkbox
        label="Caso não possua Nº de registro, confirme estar ciente da restrição de conteúdo e da agenda deste evento, não consumindo conteúdos exclusivos para profissionais prescritores."
        checked={cognizant}
        onClick={() => setCognizant(!cognizant)}
      />

      <Space mt={2} />

      <Button width="30" color="primary" disabled={!cognizant} onClick={() => handleOnSubmit()}>
        {loading ? 'Enviando...' : 'Enviar'}
      </Button>
    </>
  );
};
