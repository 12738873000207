import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Provider } from '@ederzadravec/jade-ui';
import { LanguageProvider } from './contexts/Language';
import { NotificationProvider } from './contexts/Notifications';

import { Toastr } from 'components';

import { Store } from 'contexts';
import { theme } from 'assets/theme';

import Routes from './Routes';

const GlobalStyle = createGlobalStyle`
  * {
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-family: 'Ubuntu';
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;

    &::-webkit-scrollbar{
      width: 5px;
      height: 10px;
    }
    &::-webkit-scrollbar-button{}
    &::-webkit-scrollbar-track{
      background: #f1f2f3;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track-piece{}
    &::-webkit-scrollbar-thumb{
      background: #581A69;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-corner{}
    &::-webkit-resizer{}

    &:focus{
      outline:none;
    }
  }

`;

function App() {
  const appTheme = React.useMemo(theme, []);
  return (
    <>
      <GlobalStyle />

      <Provider theme={appTheme}>
        <Store.Provider>
          <LanguageProvider>
            <NotificationProvider>
              <Toastr.Component />
              <Routes />
            </NotificationProvider>
          </LanguageProvider>
        </Store.Provider>
      </Provider>
    </>
  );
}

export default App;
