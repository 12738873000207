import React from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 14px;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
`;

const ContainItem = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SelectLanguage = ({ language, handleOnToggles, IslinkEs }) => {
  if (!IslinkEs) return <></>;

  return (
    <Container>
      <ContainItem>
        <Text size="18" color="primary" type="h" bold center>
          {language === 'portuguese' ? 'TRADUÇÃO EM ESPANHOL' : 'TRADUÇÃO EM PORTUGUES'}
        </Text>

        <Space mt={2} />

        <ContentButton>
          <Button center onClick={() => handleOnToggles()}>
            ATIVAR
          </Button>
        </ContentButton>
      </ContainItem>
    </Container>
  );
};
