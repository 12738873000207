export const getDimensions = () => {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  }
}

// xs
export const isXS = () => {
  const { width } = getDimensions();

  return width <= 600;
}

// sm
export const isSM = () => {
  const { width } = getDimensions();

  return width > 600 && width <= 768;
}

// md
export const isMD = () => {
  const { width } = getDimensions();

  return width > 768 && width <= 992;
}

// lg
export const isLG = () => {
  const { width } = getDimensions();

  return width > 992 && width <= 1200;
}

// xl
export const isXL = () => {
  const { width } = getDimensions();

  return width > 1200;
}
