import React, { useState } from 'react';
import { Grid } from '@ederzadravec/jade-ui';

import LogoBrand from 'assets/images/brand.svg';
import ImageLeft from 'assets/images/image_left.svg';

import { Welcome } from './Welcome';
import { FormLogin } from './FormLogin';
import { FormRecover } from './FormRecover';
import { FormRegister } from './FormRegister';
import { FormRegisterConfirm } from './FormRegisterConfirm';

import { Flags } from './Flags';

import { Container, ContentForm, ImageLogo, Image, Footer } from './style';

export default () => {
  const [action, setAction] = useState('welcome');

  const render = () => {
    switch (action) {
      case 'welcome':
        return <Welcome setAction={setAction} />;
      case 'recover':
        return <FormRecover setAction={setAction} />;
      case 'login':
        return <FormLogin setAction={setAction} />;
      case 'register':
        return <FormRegister setAction={setAction} />;
      case 'registerConfirm':
        return <FormRegisterConfirm setAction={setAction} />;
    }
  };

  return (
    <Container>
      <Flags />

      <Grid size={{ md: 6, xs: 12 }}>
        <Image src={ImageLeft} alt="Logo" />
      </Grid>

      <Grid size={{ md: 6, xs: 12 }}>
        <ContentForm>
          <ImageLogo src={LogoBrand} alt="Logo" />

          <>{render()}</>
        </ContentForm>
      </Grid>

      <Footer center bold="500">
        Desenvolvido por BTO+ Comunicação e Deploy. Future Agency
      </Footer>
    </Container>
  );
};
