import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { TableList, Loader, Button } from '@ederzadravec/jade-ui';
import { FaCheckDouble, FaCheck } from 'react-icons/fa';

import { FormPoll, Text, Modal } from 'components';
import { ListPollResume } from './';
import { useService } from 'service';

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
`;

const IconVisible = styled(FaCheckDouble)`
  margin: 0 0 0 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const IconNotVisible = styled(FaCheck)`
  margin: 0 0 0 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.dark.main};
`;

const ModalResume = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Text type="link" size="16" onClick={() => setShowModal(!showModal)}>
        Exibir
      </Text>
      {showModal && (
        <Modal title="Resumo da Votação" onClose={() => setShowModal(!showModal)}>
          <ListPollResume data={data} />
        </Modal>
      )}
    </>
  );
};

const Update = ({ data, refresh }) => {
  const RouteParams = useParams();
  const [, { loading }, updateVisiblePoll] = useService(
    'patch',
    `/room/${RouteParams.room}/poll/${data._id}/active`,
    {},
    false
  );

  const handleOnIsVisible = async (id, visible) => {
    await updateVisiblePoll({
      _id: id,
      isVisible: !visible,
    });

    refresh();
  };

  if (loading) return null;

  return (
    <Text
      type="link"
      color="dark"
      center
      onClick={() => handleOnIsVisible(data._id, data.isVisible)}>
      {data.isVisible === false ? <IconNotVisible /> : <IconVisible />}
    </Text>
  );
};

export const Poll = () => {
  const [showForm, setShowForm] = React.useState(false);

  const RouteParams = useParams();
  const [pollsData, { loading }, refreshPolls] = useService(
    'get',
    `/room/${RouteParams.room}/poll`
  );

  const handleOnCloseForm = refresh => {
    setShowForm(false);

    if (refresh) refreshPolls();
  };

  const listConfig = [
    {
      name: 'Name',
      size: { md: 8 },
      dataPath: 'name.pt',
    },
    {
      name: 'Ação',
      size: { md: 2 },
      dataPath: data => <Update data={data} refresh={refreshPolls} />,
    },
    {
      name: 'Resumo',
      size: { md: 2 },
      dataPath: data => <ModalResume data={data} />,
    },
  ];

  const polls = pollsData?.success?.polls?.data || [];

  return (
    <>
      <Loader show={loading}>
        <TableList config={listConfig} data={polls} />
      </Loader>

      <Footer>
        <Button onClick={() => setShowForm(true)}>Novo</Button>
      </Footer>

      {showForm && <FormPoll onClose={handleOnCloseForm} id_room={RouteParams.room} />}
    </>
  );
};
