import React, { useContext } from 'react';
import { Loader, Button } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { ContextLanguage } from 'contexts';

import { PlayerVideo } from '../PlayerVideo';
import { Reactions } from '../LiveInteractions/Reactions';

import { useService } from 'service';

import * as S from './style';

export const ContentVideo = ({ idRoom }) => {
  const [showContent, setShowContent] = React.useState(false);
  const [dataRoom, { loading }] = useService('get', `/app/room/${idRoom}`, {}, true, [idRoom]);
  const [translated, setTranslated] = React.useState(false);

  const room = dataRoom?.success?.room || [];

  if (!dataRoom) return '';

  const cxtLang = useContext(ContextLanguage);

  return (
    <Loader show={loading}>
      <S.Container>
        <PlayerVideo link={room.link[cxtLang.lang]} />

        <S.Content>
          <Reactions />

          <Space mt="2" />
          
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text size="22" type="h" color="secondary" bold="500">
              {room.title[cxtLang.lang]}
            </Text>

            {/* <Button size="22" color="primary" onClick={() => setTranslated(!translated)}>
              {translated ? 'Áudio Original' : 'Traduzir'}
            </Button> */}
          </div>

          <Space mt="2" />

          {!showContent ? (
            <S.ContentDescription>
              <S.ContainerFullContent
                dangerouslySetInnerHTML={{ __html: room.resumeDescription[cxtLang.lang] }}
              />

              {/* <Space mt={2} />

              <Text
                type="link"
                size="16"
                color="secondary"
                onClick={() => setShowContent(!showContent)}>
                {cxtLang.lang === 'pt' && '...Continuar Lendo '}
                {cxtLang.lang === 'en' && '...Continue Reading '}
                {cxtLang.lang === 'es' && '...Sigue Leyendo '}
              </Text> */}
            </S.ContentDescription>
          ) : (
            <ContentDescription>
              <S.ContainerFullContent
                dangerouslySetInnerHTML={{ __html: room.description[cxtLang.lang] }}
              />

              <Space mt={2} />

              <Text
                type="link"
                size="16"
                color="secondary"
                onClick={() => setShowContent(!showContent)}>
                {cxtLang.lang === 'pt' && '...Recolher'}
                {cxtLang.lang === 'en' && '...Decrease'}
                {cxtLang.lang === 'es' && '...Disminuir'}
              </Text>
            </ContentDescription>
          )}
        </S.Content>
      </S.Container>
    </Loader>
  );
};
