export const wellcome = {
  es: {
    title: 'Sea bienvenido',
    descripition:
      'Atención: Haga clic en INICIAR e ingrese la contraseña que se envió a su correo electrónico.',
    labelButtonLogin: 'INICIAR',
    labelButtonRegister: 'REGISTRAR',
  },
  en: {
    title: 'Welcome',
    descripition:
      'Attention: Click LOGIN and enter the password was sent to your email.',
    labelButtonLogin: 'LOGIN',
    labelButtonRegister: 'REGISTER',
  },
  pt: {
    title: 'Seja bem-vindo(a)',
    descripition: 'Atenção: Clique em ENTRAR e faça o login com a senha que foi enviada para o seu e-mail.',
    labelButtonLogin: 'ENTRAR',
    labelButtonRegister: '1º ACESSO',
  },
};
