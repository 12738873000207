import React, { useContext } from 'react';
import styled from 'styled-components';

import EN from 'assets/images/flags/united_states_flag.png';
import PT from 'assets/images/flags/brazil_flag.png';
import ES from 'assets/images/flags/spain_flag.png';

import { ContextLanguage } from 'contexts';

const ImageFlag = styled.img`
  width: 40px;
`;

export const Flags = () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <div
      style={{
        display: 'flex',
        background: 'white',
        alignItems: 'center',
        gridGap: 8,
      }}>
      <span
        style={{
          padding: '6px 6px 3px 6px',
          cursor: 'pointer',
          border: cxtLang.lang === 'pt' ? '1px solid #009CC0' : '1px solid transparent',
          borderRadius: 4,
        }}
        onClick={() => cxtLang.setLang('pt')}>
        <ImageFlag src={PT} />
      </span>

      <span
        style={{
          padding: '6px 6px 3px 6px',
          cursor: 'pointer',
          border: cxtLang.lang === 'es' ? '1px solid #009CC0' : '1px solid transparent',
          borderRadius: 4,
        }}
        onClick={() => cxtLang.setLang('es')}>
        <ImageFlag src={ES} />
      </span>

      {/* <span
        style={{
          padding: '6px 6px 3px 6px',
          cursor: 'pointer',
          border: cxtLang.lang === 'en' ? '1px solid #009CC0' : '1px solid transparent',
          borderRadius: 4,
        }}
        onClick={() => cxtLang.setLang('en')}>
        <ImageFlag src={EN} /> 
      </span> */}
    </div>
  );
};
