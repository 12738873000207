import React, { useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Text, Space, Button } from 'components';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  margin: 16px 0 32px;
  width: 100%;
`;

const Option = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const ListPollResume = ({ data }) => {
  const { room: idRoom } = useParams();

  const cxtLang = useContext(ContextLanguage);

  const [queryPoll, { loading }, fetchPoll] = useService(
    'get',
    `/room/${idRoom}/poll/${data._id}/result`,
    {},
    true,
    [data._id]
  );

  const dataPoll = queryPoll?.success?.poll || [];

  return (
    <Container>
      <Text size="22" color="dark">
        {data?.description[cxtLang.lang]}
      </Text>

      <Space mt={2} />

      {dataPoll.map(item => (
        <Option key={item._id}>
          <Text size="16" bold type="h">
            {item.percent}% responderam: <Text size="16">{item.label[cxtLang.lang]}</Text>
          </Text>
          <Space mt={2} />
        </Option>
      ))}

      <Button onClick={fetchPoll} loading={loading}>
        Atualizar
      </Button>
    </Container>
  );
};
