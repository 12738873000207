import styled from 'styled-components';
import { Text } from 'components';

export const Container = styled.div`
  background: #0adbe3;
  border-radius: 8px 8px 0 0;
  width: 100%;
  padding: 24px;
  position: absolute;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    border-right: 0px solid ${({ theme }) => theme.palette.primary.main};
    padding-bottom: 30px;
  }
`;

export const Message = styled(Text)`
  margin-top: 16px;
  overflow-wrap: anywhere;
`;
