import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

import * as S from './style';

export const PlayerVideo = ({ link }) => {
  return (
    <S.Container>
      <S.ContainerVideo>
        <ReactHlsPlayer src={link} autoPlay={true} controls={true} width="100%" height="auto" />
      </S.ContainerVideo>
    </S.Container>
  );
};