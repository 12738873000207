import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { hooks, FormGrid, TextInput } from '@ederzadravec/jade-ui';

import { Space, Text, Button } from 'components';
import { useService } from 'service';
import { Store } from 'contexts';

const Container = styled.div`
  padding: 24px 0;
`;

const validations = {};

export const FormLogin = ({ setIsRecover }) => {
  const { setSession, setStore } = React.useContext(Store.Context);
  const history = useHistory();

  const [, { loading: validatingLogin }, login] = useService('post', `admin/login`, null, false);

  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      email: form.getValue('email'),
      password: form.getValue('password'),
    };

    const result = await login(data);

    if (result?.success?.status === 'OK') {
      setSession({
        session: result.success.token,
        type: result.success.user.type,
      });

      setStore({
        user: result.success.user,
      });

      return history.push('/admin');
    }

    if (result?.error) {
      return form.setError(result?.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'email',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'password',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          type: 'password',
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={2} />

        <Button loading={validatingLogin} center>
          ENTRAR
        </Button>
      </FormGrid>

      <Space mt={2} />

      {/* <Text type="p" center>
        Esqueceu sua senha? Clique{' '}
        <Text color="primary" type="link" bold onClick={() => setIsRecover(true)}>
          aqui!
        </Text>
      </Text>

      <Space mt={1} />

      <Text type="p" center>
        Se você ainda não tem cadastro clique{' '}
        <Text color="primary" type="link" bold onClick={() => setIsRecover(true)}>
          aqui
        </Text>
      </Text> */}
    </Container>
  );
};
