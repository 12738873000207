import styled from 'styled-components';
import { GrTrophy } from 'react-icons/gr';

import { Loader, TextInput, FormGrid as JDFormGrid } from '@ederzadravec/jade-ui';

import { Box } from '../Box/Box';
import { Text } from '../Text';

export const Container = styled(Box)`
  width: 100%;
`;

export const ContentWalls = styled(Loader)`
  margin: 24px 0;
  max-height: 400px;
  overflow: auto;
`;

export const Message = styled.div`
  margin: 8px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.light};
  width: 100%;
`;

export const HeaderMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const ContentAvatar = styled.div`
  position: relative;
`;

export const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 10px;
  border-radius: 8px;
`;

export const IconTrophy = styled(GrTrophy)`
  position: absolute;
  bottom: 0;
  right: 6px;
  border-radius: 4px;
  font-size: 22px;
  padding: 3px;

  background: ${({ theme }) => theme.palette.tertiary.light};
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.black.light};
  padding: 8px;
  border-radius: 8px;
  flex: 1;
`;

export const BodyContentInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const TextMessage = styled(Text)`
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 18px;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey.dark};
`;

export const FormGrid = styled(JDFormGrid)`
  max-height: 85px;
  position: relative;
`;

export const Button = styled.div`
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.grey.light};
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 4px;
`;

export const CustomTextArea = styled(TextInput)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 15px;
  padding: 24px 8px;
  margin: 32px auto 0;
`;
