import React from 'react';

import { ButtonStyle } from './button.style';

export const Button = ({ children, onClick, ...props }) => {
  return (
    <ButtonStyle onClick={onClick} {...props}>
      {children}
    </ButtonStyle>
  );
};

Button.defaultProps = {
  onClick: () => {},
};
