import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text } from 'components';
import { useService } from 'service';

import { Form } from './Form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentButton = styled.div`
  display: inline-block;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export default ({ history, location }) => {
  const [speakersData, { loading }, refreshSpeakers] = useService('get', '/speaker?limit=100');

  const BASE_URL = '/admin/speaker';

  const configList = [
    {
      name: 'Nome',
      dataPath: 'name',
      size: {
        md: 12,
      },
    },
    // {
    //   name: 'E-mail',
    //   dataPath: 'email',
    //   size: {
    //     md: 7,
    //   },
    // },

    // {
    //   name: 'Açao',
    //   dataPath: data => (
    //     <Text type="link" color="dark" onClick={() => history.push(`${BASE_URL}/edit/${data._id}`)}>
    //       Editar
    //     </Text>
    //   ),
    //   size: {
    //     md: 1,
    //   },
    // },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="dark">
          Palestrantes
        </Text>
        <ContentButton>
          <Button
            onClick={() => {
              history.push(`${BASE_URL}/new`);
            }}>
            Novo
          </Button>
        </ContentButton>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={speakersData?.success?.speakers?.data || []}
            // onSelect={() => {
            //   history.push(`${BASE_URL}/10`);
            // }}
          />
        </Content>
      </Loader>

      <Switch>
        <Route path={`${BASE_URL}/new`}>
          <Form
            onClose={() => {
              history.push(BASE_URL);
              refreshSpeakers();
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};
