import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Text, Space } from 'components';
import { useService } from 'service';

import { Form } from './Form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.dark.light};
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Banner = () => {
  let { id } = useParams();

  const [bannerData, { loading }] = useService('get', `banner/${id}`);

  return (
    <Container>
      <Header>
        <Text type="h" size="24" bold color="primary">
          {bannerData?.success?.banner?.name}
        </Text>
      </Header>

      <Space mt={2} />
      <hr />
      <Space mt={2} />

      <Form bannerData={bannerData} />
    </Container>
  );
};
