export const textsFormRegisterConfirm = {
  es: {
    title: 'Confirmar registro',
    labelFieldEmail: 'E-mail',
    labelButton: 'Enviar',
    uriBackToLogin: 'Volver a iniciar sesión',
    fieldEmpty: 'Campo Obligatorio',
    textInvalidEmail: 'Email inválido. No se puede continuar con el registro.',
    successMessage:
      '¡Revise su correo electrónico para ver el enlace para completar su registro en nuestro sitio web!',
  },
  en: {
    title: 'Confirm Registration',
    labelFieldEmail: 'E-mail',
    labelButton: 'Send',
    uriBackToLogin: 'Back to login',
    fieldEmpty: 'Required field',
    textInvalidEmail: 'Invalid email. Unable to proceed with registration.',
    successMessage: 'Check your email for the link to complete your registration on our website.',
  },
  pt: {
    title: 'Confirmar Cadastro',
    labelFieldEmail: 'E-mail',
    labelButton: 'Enviar',
    uriBackToLogin: 'Volte ao login',
    fieldEmpty: 'Campo Obrigatório',
    textInvalidEmail: 'Email Inválido. Impossível prosseguir com o cadastro.',
    successMessage: 'Confira em seu e-mail o link para concluir o cadastro em nosso site.',
  },
};
