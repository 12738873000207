import React, { useEffect, useState } from 'react';
import { Space } from '@ederzadravec/jade-ui';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';

import { Text } from 'components';

import { Container, ContentPagesItens, ContentInfo, Item, ItemLoading } from './pagination.style';

export const Pagination = ({ data, returnS, setReturnS, noInfos }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <>
      {data?.lastPage > 1 ? (
        <Container>
          {!noInfos && (
            <ContentInfo>
              <Text size={14}>Pagina atual: {data.currentPage}</Text>
              <Space right={2} inline />
              <Text size={14}>Total: {data.lastPage}</Text>
            </ContentInfo>
          )}

          <ContentPagesItens>
            {loading ? (
              <ItemLoading>Carregando</ItemLoading>
            ) : (
              <>
                <Item
                  onClick={() => {
                    setLoading(true);
                    setReturnS(`&page=${data.currentPage - 1}`);
                  }}>
                  <FaCaretLeft />
                </Item>
                {[...new Array(data.lastPage)].map((i, k) => (
                  <Item
                    key={k}
                    active={data.currentPage === k + 1}
                    onClick={() => {
                      setLoading(true);
                      setReturnS(`&page=${k + 1}`);
                    }}>
                    {k + 1}
                  </Item>
                ))}
                <Item
                  onClick={() => {
                    setLoading(true);
                    setReturnS(
                      `&page=${data.currentPage === data.lastPage ? '' : data.currentPage + 1}`
                    );
                  }}>
                  <FaCaretRight />
                </Item>
              </>
            )}
          </ContentPagesItens>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};
