import React, { useContext } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import { FiArrowRight } from 'react-icons/fi';
import { hooks } from '@ederzadravec/jade-ui';

import AvatarClient from 'assets/images/avatarImage.jpg';
import { Room } from 'contexts';
import { Text, Space } from 'components';
import { FeaturedMessageOfChat } from './components/FeaturedMessageOfChat';
import { dbToDate } from 'utils/date';

import { ContextLanguage } from 'contexts';

import * as S from './style';

const validations = {
  content: [[value => !value, 'Deve ser preenchido']],
};

export const Chat = ({ idRoom }) => {
  const [loading, setLoading] = React.useState(false);
  const [scrolling, setScrolling] = React.useState(false);
  const { chat, sendChat } = React.useContext(Room.Context);

  const listOfChat = Object.values(chat || {}) || [];

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    setLoading(false);
  }, [chat]);

  const handleOnSubmit = async () => {
    form.clear();

    setLoading(true);

    const data = {
      content: form.getValue('content'),
    };

    sendChat(data);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 160) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  const formConfig = [
    [
      {
        schema: 'content',
        size: { xs: 12, md: 12 },
        type: S.CustomTextArea,
        props: schema => ({
          label: '',
          debounce: 0,
          placeholder: `${
            (cxtLang.lang === 'pt' && 'Digite uma mensagem') ||
            (cxtLang.lang === 'en' && 'Enter a message') ||
            (cxtLang.lang === 'es' && 'Ingrese un mensaje')
          }`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  const cxtLang = useContext(ContextLanguage);

  return (
    <div style={{ background: 'transparent', width: '100%', height: '100%', position: 'relative' }}>
      <S.DivScroll isScrolling={scrolling}>
        <FeaturedMessageOfChat />

        <S.Container>
          <S.Messages>
            <ScrollableFeed forceScroll>
              {listOfChat
                .filter(item => item?.hide !== true)
                .map(item => {
                  return (
                    <S.Message key={item._id}>
                      {item?.viewer?.isDetach && <S.IconTrophy />}

                      <S.HeaderMessage>
                        {item.viewer && <S.Avatar src={item.viewer.photo} />}
                        {item.user && <S.Avatar src={AvatarClient} />}

                        <S.ContentInfo isAdmin={item.user}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginBottom: 8,
                            }}>
                            <Text
                              size="15"
                              type="h"
                              color={item.user ? 'white' : 'black'}
                              bold="500">
                              {item.user && 'TOTVS'}
                              {item.viewer && item.viewer.name}
                            </Text>

                            <Space ml={2} />

                            <Text size="15" type="p" color={item.user ? 'white' : 'dark'}>
                              {dbToDate(item.createdAt, 'HH:mm')}
                            </Text>
                          </div>

                          <S.TextMessage
                            type="p"
                            size="15"
                            color={item.user ? 'white' : 'black'}
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />
                        </S.ContentInfo>
                      </S.HeaderMessage>
                    </S.Message>
                  );
                })}
            </ScrollableFeed>
          </S.Messages>

          <S.FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
            <S.Button loading={loading} color="primary" padding={18} width={10}>
              <FiArrowRight color="#333" />
            </S.Button>
          </S.FormGrid>
        </S.Container>
      </S.DivScroll>
    </div>
  );
};
