import React from 'react';
import styled from 'styled-components';
import { FaCheckDouble, FaCheck } from 'react-icons/fa';

import { Text } from '../Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  margin-bottom: 16px;
`;

const Header = styled.div`
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.light};
  min-height: auto;
`;

const Itens = styled.div`
  padding: 8px;
`;

const Item = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.dark.light};
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const IconNoVisible = styled(FaCheck)`
  font-size: 22px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.dark.light};
`;
const IconVisible = styled(FaCheckDouble)`
  font-size: 22px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ListItens = ({ label }) => {
  const [value, setValue] = React.useState(null);

  const dataItens = [
    { _id: 439274562301, title: 'Item 1' },
    { _id: 439274562302, title: 'Item 2' },
    { _id: 439274562303, title: 'Item 3' },
    { _id: 439274562304, title: 'Item 4' },
  ];

  return (
    <Container>
      <Header>
        <Text type="h" size="18" bold center>
          {label}
        </Text>
      </Header>

      <Itens>
        {dataItens.map((item, key) => (
          <Item>
            <Text key={key} type="h" size="16" bold="500">
              {item.title}
            </Text>

            <Text visible={value === item._id}>
              {value === item._id ? (
                <IconVisible onClick={() => setValue(null)} />
              ) : (
                <IconNoVisible onClick={() => setValue(item._id)} />
              )}
            </Text>
          </Item>
        ))}
      </Itens>
    </Container>
  );
};
