import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

const ListImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
`;

const Image = styled.img`
  margin: 8px;
  width: 290px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  border: 4px solid transparent;

  ${({ selected, theme }) =>
    selected &&
    `border: 4px solid ${theme.palette.tertiary.main};
  `}
`;

import { useService } from 'service';
import { addRemoveItem, isInArray } from './functions';

export const List = ({ data, mode }) => {
  const [selected, setSelected] = useState([]);

  const [, { loadingSave }, saveImages] = useService('post', '/instagram', {}, false);
  const [, { loadingRemove }, removeImages] = useService('post', '/instagram/delete', {}, false);

  useEffect(() => {
    setSelected([]);
  }, [mode]);

  return (
    <Container>
      <ListImages>
        {data?.map(item => {
          return (
            <Image
              key={item._id}
              src={`data:image/png;base64, ${item.path}`}
              onClick={async () => {
                mode === 'search'
                  ? await saveImages({ posts: [item._id] })
                  : await removeImages({ posts: [item._id] });
                addRemoveItem(selected, setSelected, item._id);
              }}
              selected={isInArray(selected, item._id)}
            />
          );
        })}
      </ListImages>
      <br />
    </Container>
  );
};
