export const homeTexts = {
  es: {
    navBarTextHI: 'Hola ',
    navBarTextSearch: 'Buscar... ',

    menuUriHomePage: 'Página de inicio',
    menuScheduleOne: 'Mi agenda',
    menuScheduleTwo: 'Mi agenda',
    menuUriSpeakers: 'Campeones de ventas',
    menuUriSettings: 'Ajustes',
    menuUriSponsors: 'Campeones de ventas',
    menuUriPosters: 'Póster',
    menuUriExit: 'Fuera',
    menuTextConfirmExit: '¿De verdad quieres irte?',
    menuConfirmButton: 'Sí',
    menuCancelButton: 'No',
  },
  en: {
    navBarTextHI: 'Hi ',
    navBarTextSearch: 'Search... ',
    menuScheduleOne: 'Schedule',
    menuScheduleTwo: 'Schedule',
    menuUriHomePage: 'Home',
    menuUriSpeakers: 'Sales champions',
    menuUriSponsors: 'Sponsors - 3D Experience',
    menuUriSettings: 'Settings',
    menuUriPosters: 'Poster',
    menuUriExit: 'Exit',
    menuTextConfirmExit: 'Do you really want to leave?',
    menuConfirmButton: 'Yes',
    menuCancelButton: 'No',
  },
  pt: {
    navBarTextHI: 'Olá ',
    navBarTextSearch: 'Pesquisar... ',
    menuUriHomePage: 'Programação',
    menuScheduleOne: 'Agenda',
    menuScheduleTwo: 'Agenda',
    menuUriSpeakers: 'Campeões de vendas',
    menuUriSponsors: 'Experiência 3D - Sponsors',
    menuUriSettings: 'Configurações',
    menuUriPosters: 'Pôster digital (e-Poster)',
    menuUriExit: 'Sair',
    menuTextConfirmExit: 'Deseja mesmo sair?',
    menuConfirmButton: 'Sim',
    menuCancelButton: 'Não',
  },
};
